import { jwtToken, BASE_URL } from './server';
import { localToIsoStringStartDay } from '../utils/dates-conversion';
import { getCompanyId, getUserId, getRoleId } from '../utils/utilities';

// Function to get details of a single project by its ID

export const getSingleProject = async (id) => {
  const url = `${BASE_URL}/getSingleProject/${id}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Function to fetch all data required for project creation

export const getAllProjectCreationData = async () => {
  const url = `${BASE_URL}/getAllProjectCreationData`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Function to create a new project with the provided data
export const postCreateProject = async (values, dummy, users) => {
  const bodyData = {
    proj_auto_id: values.projectId,
    company_id: Number(getCompanyId()),
    user_id: Number(getUserId()),
    proj_name: values.projectname.trim(),
    proj_flag: 1,
    priority_auto_id: values.prioritylabel,
    proj_short_name: values.shortname.trim(),
    des_project: values.projectdescription.trim(),
    project_template_auto_id: values.projecttemplateselect,
    workflow_auto_id: values.workflowselect,
    workflow_bug_auto_id: values.bugworkflowlabel,
    proj_estimated_hr: Number(values.estimatedhours),
    proj_start_date: values.startdate ? localToIsoStringStartDay(values.startdate) : '',
    proj_end_date: values.duedate ? localToIsoStringStartDay(values.duedate) : '',
    currency_auto_id: values.currencylabel,
    project_status_auto_id: values.projectstatuslabel,
    task_type_auto_id: values.tasktypelabel,
    industry_auto_id: values.industrylabel,
    notify_via_email: dummy,
    // notify_via_email_adminHrOwner: adminHrOwnerDummyArray,
    project_manager_id: values.projectmanagerlabel,
    Users: users,
  };
  if (!bodyData.task_type_auto_id) {
    delete bodyData.task_type_auto_id;
  }
  if (!bodyData.proj_auto_id) {
    delete bodyData.proj_auto_id;
  }
  // if (!bodyData.des_project) {
  //   delete bodyData.des_project;
  // }
  if (!bodyData.project_template_auto_id) {
    delete bodyData.project_template_auto_id;
  }
  if (!bodyData.workflow_auto_id) {
    delete bodyData.workflow_auto_id;
  }
  if (!bodyData.workflow_bug_auto_id) {
    delete bodyData.workflow_bug_auto_id;
  }
  if (!bodyData.proj_estimated_hr) {
    delete bodyData.proj_estimated_hr;
  }
  if (!bodyData.proj_start_date) {
    delete bodyData.proj_start_date;
  }
  if (!bodyData.proj_end_date) {
    delete bodyData.proj_end_date;
  }
  if (!bodyData.currency_auto_id) {
    delete bodyData.currency_auto_id;
  }
  if (!bodyData.project_status_auto_id) {
    delete bodyData.project_status_auto_id;
  }
  if (!bodyData.industry_auto_id) {
    delete bodyData.industry_auto_id;
  }
  if (!bodyData.project_manager_id) {
    delete bodyData.project_manager_id;
  }
  if (!bodyData.priority_auto_id) {
    delete bodyData.priority_auto_id;
  }
  const response = await fetch(`${BASE_URL}/createProject`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const getProjectbyUser = async (
  filteredValues,
  page,
  rowsPerPage,
  search,
  order,
  orderBy,
  dash,
  csv = '',
  queryParams
) => {
  const values = { ...filteredValues };
  let queryparams = '';

  // Updated loop using forEach
  Object.entries(values).forEach(([key, value]) => {
    queryparams += `${key}=${value}&`;
  });

  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;

  let url = `${BASE_URL}/getProjectbyUser/${getUserId()}/company/${getCompanyId()}?${queryparams}`;

  if (page) {
    url += `page=${page}&`;
  }
  if (rowsPerPage) {
    url += `page_size=${rowsPerPage}&`;
  }
  if (search) {
    url += `projectName=${search}&`;
  }
  if (csv) {
    url += `csv=${csv}&`;
  }
  if (queryParams) {
    url += `columns=${queryParams}&`;
  }

  // Check if project_status is already included in the URL
  if (!url.includes('project_status') && dash !== 1) {
    url += 'project_status=1&';
  }

  // Add 'data=1' only if 'csv' is not set to '1'
  if (csv !== '1' && !url.includes('data') && !url.includes('pdf=1')) {
    url += 'data=1&';
  }

  // Remove the trailing '&' from the URL if there is one
  if (url.endsWith('&')) {
    url = url.slice(0, -1);
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();
  return data;
};

export const getAllTaskCreationData = async (projId) => {
  const url = `${BASE_URL}/getAllTaskCreationData/${getUserId()}/company/${getCompanyId()}/project/${projId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postProjectStatusChange = async (id, status, task) => {
  const queryParams = new URLSearchParams({ status_id: status.project_status_auto_id }).toString();
  const url = `${BASE_URL}/updateStatus?${queryParams}`;
  const bodyData = {
    status_id: status.project_status_auto_id,
    proj_auto_id: task.proj_auto_id,
  };
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
export const postProjectPriorityChange = async (priority, task) => {
  const queryParams = new URLSearchParams({ priority_id: priority.priority_auto_id }).toString();
  const url = `${BASE_URL}/updateStatus?${queryParams}`;
  const bodyData = {
    priority_id: priority.priority_auto_id,
    proj_auto_id: task.proj_auto_id,
  };
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const getUserProjects = async () => {
  const url = `${BASE_URL}/getUserProjects/company/${getCompanyId()}/user/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const deleteProject = async (project) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/deleteProjectByAdmin`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: Number(getCompanyId()),
      // user_id: Number(getUserId()),
      proj_auto_id: project,
      role_id: Number(getRoleId()),
    }),
  });
  const data = await response.json();
  return data;
};

export const getUserByProject = async (project) => {
  const url = `${BASE_URL}/getUsersByProject/${project}/Company/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getProjectEstHr = async (project) => {
  const url = `${BASE_URL}/getProjectEstHr/${project}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getProjectSpenttHr = async (project) => {
  const url = `${BASE_URL}/getProjectSpenttHr/${project}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getTaskActivityLogByProject = async (projId, compId, page, pageSize) => {
  const url = `${BASE_URL}/getTaskActivityLogByProject/project/${projId}/company/${compId}?page=${page}&page_size=${pageSize}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

import {
  getRoleId,
  getUserState,
  setUserState,
  getUserId,
  getCompanyId,
  setUserCountry,
  getUserCity,
  setUserCity,
  setCountryCodeIso,
  getCountryCodeIso,
  getJwtToken,
} from '../utils/utilities';

import {
  localToUTCIsoStringStartDay,
  localToIsoStringStartDay,
  dateToLocal,
  localToUTCIsoStringEndDay,
} from '../utils/dates-conversion';
import { localToUTC } from '../utils/dates';

//
export const BASE_URL = process.env.REACT_APP_BASE_URL_DEV || 'https://scrum.api.tradefsd.com';
// export const BASE_URL = 'https://scrum.api.tradefsd.com';
// export const BASE_URL = 'http://167.99.62.73:3003';
// const BASE_URL= 'http://localhost:3002';
// const BASE_URL = 'https://app.tradefsd.com';
// const BASE_URL = 'https://scrumdroid.com:3001';
// const BASE_URL = 'https://app.scrumdroid.com';
// const BASE_URL = 'https://stage.back.scrumdroid.com/'

export const jwtToken = () => getJwtToken();

export const GetUserLocation = async () => {
  setUserCountry(null);
  setUserState(null);
  setUserCity(null);
  const response = await fetch(`https://ipfind.co/me?auth=dbe817fa-2c28-4e15-be56-40ad7baadcff`);
  const data = await response.json();
  if (response.status === 200) {
    setUserCountry(data.country);
    setUserState(data.region);
    setUserCity(data.city);
    setCountryCodeIso(data.country_code);
  } else {
    setUserCountry(null);
    setUserState(null);
    setUserCity(null);
  }
  return data.status === 'success' ? data : null;
};

export const getIndustries = async () => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/get-industries`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postEnableuser = async (values) => {
  let user_country = null;
  let user_state = null;
  let user_city = null;
  getCountryCodeIso() !== 'null' && (user_country = getCountryCodeIso());
  getUserState() !== 'null' && (user_state = getUserState());
  getUserCity() !== 'null' && (user_city = getUserCity());

  const val = {
    user_password: values.password,
    user_country,
    user_city,
    user_state,
  };

  const url = `${BASE_URL}/enableuser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${values.myuser}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  return data;
};

export const deleteTaskByAdmin = async (id) => {
  const url = `${BASE_URL}/deleteTaskByAdmin`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: parseInt(getCompanyId(), 10),
      user_id: parseInt(getUserId(), 10),
      role_id: parseInt(getRoleId(), 10),
      proj_task_auto_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

export const getMissingTask = async (
  size,
  page,
  user_timesheet,
  userid,
  projectManager,
  search,
  selectedHours,
  order,
  orderBy,
  dateRange,
  csv = '',
  pdfParams,
  userTimeZone
) => {
  const coulumnParams = pdfParams || '';
  const today = new Date();
  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000); // Calculate yesterday
  const last30Days = new Date(yesterday.getTime() - 30 * 24 * 60 * 60 * 1000); // Use yesterday's date for the starting point
  const userZoneTime = userTimeZone || '';
  const startDateRange = dateRange[0] ? dateToLocal(dateRange[0]) : last30Days;
  const endDateRange = dateRange[1] ? dateToLocal(dateToLocal(dateRange[1])) : yesterday;
  console.log('startDateRange', startDateRange);
  console.log('endDateRange', endDateRange);
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  if (csv === '') queryparams += 'data=1&';
  const url = `${BASE_URL}/missingTaskTimesheet/${Number(
    getCompanyId()
  )}?user_time_zone=${userZoneTime}&page_size=${size}&page=${page}&user_timesheet=${user_timesheet}&assign_to=${userid.toString()}&project_manager=${projectManager.toString()}&givenHours=${selectedHours}&search=${search}&${queryparams}&startDate=${localToIsoStringStartDay(
    startDateRange
  )}&endDate=${localToIsoStringStartDay(endDateRange)}${csv}&columns=${coulumnParams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAway = async () => {
  const url = `${BASE_URL}/getAway`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Sign in and away status_id's and status strings for dropdown
export const getSignAway = async () => {
  const url = `${BASE_URL}/getSignAway`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get recent swipes by user
export const getSignInOut = async () => {
  const url = `${BASE_URL}/getClockInByUser/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postSignInOut = async (
  status,
  channel,
  late,
  lateTimeValue,
  reason,
  lateTimeInMinutes,
  lateSuggestedTimeValue
) => {
  const url = `${BASE_URL}/insertAttendance`;
  const suggestedSignInTime =
    status === 'Signing in' && late === 1 ? (reason ? null : lateTimeValue.toLocaleString()) : null;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: Number(`${getUserId()}`),
      company_id: Number(`${getCompanyId()}`),
      attendance_name: status,
      channel:
        channel === 'office'
          ? status === 'Signing in'
            ? `${status} from ${channel}`
            : `${status}`
          : status === 'Signing in'
            ? `${status} ${channel}`
            : `${status}`,
      channel_flag: channel === 'office' ? 1 : 0,
      suggested_sign_in_time_utc:
        status === 'Signing in' && late === 1 ? (reason ? null : localToUTC(suggestedSignInTime)) : null, // Use the UTC time here
      suggested_attendance_flag: status === 'Signing in' && late === 1 ? (reason ? 0 : 1) : 0,
      reason: status === 'Signing in' ? reason || null : '',
      user_is_late: status === 'Signing in' ? lateTimeInMinutes : null,
      user_is_late_suggested: status === 'Signing in' && late === 1 ? (reason ? null : lateSuggestedTimeValue) : null,
    }),
  });
  const data = await response.json();
  return data;
};

// export const updateUserSignInStatus = async (info, user, status) => {
//   if (status === 'weekly') {
//     const bodyData = {
//       user_id: user,
//       company_id: getCompanyId(),
//       monday: info[0].status ? info[0].status : 0,
//       tuesday: info[1].status ? info[1].status : 0,
//       wednesday: info[2].status ? info[2].status : 0,
//       thursday: info[3].status ? info[3].status : 0,
//       friday: info[4].status ? info[4].status : 0,
//       saturday: info[5].status ? info[5].status : 0,
//       sunday: info[6].status ? info[6].status : 0,
//       remotely_tag_monthly: 0,
//     };
//     const url = `${BASE_URL}/updateUserSignStatus?weekly=1`;
//     const response = await fetch(url, {
//       method: 'put',
//       headers: {
//         Authorization: `Bearer ${jwtToken()}`,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(bodyData),
//     });
//     const data = await response.json();
//     return data;
//   }
//   const bodyData1 = {
//     user_id: user,
//     company_id: getCompanyId(),
//     full_date: info,
//     created_date: moment().format('DD-MM-YYYY'),
//     office_tag: 1,
//     office_tag_monthly: 0,
//     active: 1,
//   };
//   const url = `${BASE_URL}/updateUserSignStatus?monthly=1`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyData1),
//   });
//   const data = await response.json();
//   return data;
// };

// export const updateCompanyWorkday = async (info) => {
//   const bodyData = {
//     title: info.name,
//     description: info.description,
//     active: info.active ? 1 : 0,
//     monday: info.monday ? 1 : 0,
//     tuesday: info.tuesday ? 1 : 0,
//     wednesday: info.wednesday ? 1 : 0,
//     thursday: info.thursday ? 1 : 0,
//     friday: info.friday ? 1 : 0,
//     saturday: info.saturday ? 1 : 0,
//     sunday: info.sunday ? 1 : 0,
//     workday_id: info.id,
//   };

//   if (bodyData?.workday_id === 0 || !bodyData?.workday_id) delete bodyData?.workday_id;

//   const url = `${BASE_URL}/updateCompanyWorkday`;
//   const response = await fetch(url, {
//     method: 'post',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyData),
//   });
//   const data = await response.json();
//   return data;
// };

// export const deleteWorkday = async (info) => {
//   const url = `${BASE_URL}/deleteWorkday`;
//   const response = await fetch(url, {
//     method: 'delete',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       workday_id: info.workday_id,
//       title: info.title,
//     }),
//   });
//   const data = await response.json();
//   return data;
// };
// export const deleteHoliday = async (info) => {
//   const url = `${BASE_URL}/deleteCompanyHoliday`;
//   const response = await fetch(url, {
//     method: 'delete',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       holidays_id: info.holidays_id,
//       company_id: info.company_id,
//     }),
//   });
//   const data = await response.json();
//   return data;
// };

// export const GetManagers = async () => {
//   const url = `${BASE_URL}/getFunctionalProjectManagers`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const getCompanyUsersForList = async (page, rows, query) => {
//   let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}?sortByName=asc&`;
//   if (query && query !== '') url += `search=${query}&`;

//   if (page !== null) url += `page=${page}&`;

//   if (rows !== null) url += `page_size=${rows}`;

//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const GetAllComingHolidays = async () => {
//   const url = `${BASE_URL}/getAllCompanyComingHolidays`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const GetCompanyComingUserBirthDay = async () => {
//   const url = `${BASE_URL}/getCompanyComingUserBirthDay`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const GetUserLastLogin = async () => {
//   const url = `${BASE_URL}/getUserLastLogin`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };
// export const GetUpcomingSalaryReviews = async () => {
//   const url = `${BASE_URL}/dashboard/getComingCompanySalaryReview`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   // debugger;
//   return data;
// };
// export const GetCompanyComingLeavesApproval = async () => {
//   const url = `${BASE_URL}/getComingCompanyLeave`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };
// export const GetCompanyComingUserAnniversary = async () => {
//   const url = `${BASE_URL}/dashboard/getUpComingUserAnniversary?sort=asc`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };
// export const GetCompanyComingEndProbation = async () => {
//   const url = `${BASE_URL}/getUpComingEndProbation`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };
// export const deleteEntitlement = async (info) => {
//   const url = `${BASE_URL}/deleteCompanyEntitlement/${info.entitlement_id}`;
//   const response = await fetch(url, {
//     method: 'delete',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const postLeaveApproveByFirstApprover = async (info, no) => {
//   const bodyDate = {
//     employee_leave_id: info.employee_leave_id,
//     leave_flag: no,
//   };

//   const url = `${BASE_URL}/leaveApproveByFirstApprover`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyDate),
//   });
//   const data = await response.json();
//   return data;
// };

// export const postLeaveApproveBySecondApprover = async (info, no) => {
//   const bodyDate = {
//     employee_leave_id: info.employee_leave_id,
//     leave_flag: no,
//   };

//   const url = `${BASE_URL}/leaveApproveBySecondApprover`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyDate),
//   });
//   const data = await response.json();
//   return data;
// };

// export const postLeaveApproveByHR = async (info, no, comment) => {
//   const bodyDate = {
//     employee_leave_id: info.employee_leave_id,
//     leave_flag: no,
//     message: comment,
//   };

//   const url = `${BASE_URL}/leaveApproveByHr/2023`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyDate),
//   });
//   const data = await response.json();
//   return data;
// };

// export const postLeaveApproveByHR = async (info, no, comment, year) => {
//   const bodyDate = {
//     employee_leave_id: info.employee_leave_id,
//     leave_flag: no,
//     message: comment,
//   };

//   const currentYear = new Date().getFullYear();
//   const url = `${BASE_URL}/leaveApproveByHr/${year}`;

//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyDate),
//   });

//   const data = await response.json();
//   return data;
// };

// export const approveSuggestedAttendance = async (info, approver, flag) => {
//   const bodyDate =
//     approver === 'hr'
//       ? {
//           attendance_auto_id: info.attendance_auto_id,
//           approved_by_hr: flag,
//         }
//       : approver === 'fm'
//       ? {
//           attendance_auto_id: info.attendance_auto_id,
//           approved_by_functional_manager: flag,
//         }
//       : {
//           attendance_auto_id: info.attendance_auto_id,
//           approved_by_recruitment_manager: flag,
//         };

//   const url = `${BASE_URL}/approveSuggestedAttendance`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyDate),
//   });
//   const data = await response.json();
//   return data;
// };

// export const GetAllRoles = async (search, page, rowsPerPage, order, orderBy) => {
//   let queryparams = '';
//   if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
//   const url = `${BASE_URL}/adminGetUserRoles?page=${page}&page_size=${rowsPerPage}&search=${search}&${queryparams}`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const adminUserEnableDisable = async (usedId, no) => {
//   const bodyDate = {
//     user_is_active: no,
//     id: usedId,
//   };

//   const url = `${BASE_URL}/adminUserEnableDisable`;
//   const response = await fetch(url, {
//     method: 'post',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyDate),
//   });
//   const data = await response.json();
//   return data;
// };

// export const getCompaniesAdmin = async (page, page_size, search, order, orderBy) => {
//   // const jwtToken = localStorage.getItem('jwtToken');
//   let queryparams = '';
//   if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
//   const response = await fetch(
//     `${BASE_URL}/admingetallcompanies?page=${page}&page_size=${page_size}&search=${search}&${queryparams}`,
//     {
//       method: 'get',
//       headers: {
//         Authorization: `Bearer ${jwtToken()}`,
//         'Content-Type': 'application/json',
//       },
//     }
//   );
//   const data = await response.json();
//   if (response.status >= 400) {
//     console.error(response.status);
//   }
//   return data;
// };

// export const adminDeleteUser = async (userId) => {
//   const url = `${BASE_URL}/adminDeleteUser/${userId}`;
//   const response = await fetch(url, {
//     method: 'delete',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const adminDeleteCompany = async (id = '') => {
//   const url = `${BASE_URL}/adminDeleteCompany/${id}`;
//   const response = await fetch(url, {
//     method: 'delete',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const adminCompanyAction = async (id = '', flag = -1) => {
//   const bodyData = {
//     company_flag: flag,
//   };
//   const url = `${BASE_URL}/adminCompanyAction/${id}`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyData),
//   });
//   const data = await response.json();
//   return data;
// };

// export const adminGetEmailTemplate = async () => {
//   const url = `${BASE_URL}/adminGetEmailTemplate`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const GetAllEmailSettings = async () => {
//   const url = `${BASE_URL}/adminGetEmailSetting`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const adminUpdateEmailTemplate = async (bodyData = []) => {
//   const url = `${BASE_URL}/adminUpdateEmailTemplate`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyData),
//   });
//   const data = await response.json();
//   return data;
// };

// export const enableCompany = async (params_token) => {
//   const urlToken = params_token;
//   const response = await fetch(`${BASE_URL}/enableCompany`, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${urlToken}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();

//   if (response.status >= 400) {
//     // throw new Error(data.errors);
//     console.error(response.status);
//   }
//   return data;
// };

// export const addCompanyDocuments = async (dataToSend) => {
//   const url = `${BASE_URL}/companyDocuments`;
//   const response = await fetch(url, {
//     method: 'post',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(dataToSend),
//   });
//   const data = await response.json();
//   return data;
// };

// export const updateCompanyDocuments = async (dataToSend, companyDocumentAutoId) => {
//   const url = `${BASE_URL}/updateCompanyDocument?company_document_auto_id=${companyDocumentAutoId}`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(dataToSend),
//   });
//   const data = await response.json();
//   return data;
// };

// export const getCompanyDocument = async (filterValues, page, rowsPerPage, order, orderBy, searchQuery, companyId) => {
//   const values = { ...filterValues };
//   let queryparams = '';
//   for (const [key, value] of Object.entries(values)) {
//     queryparams += `${key}=${value}&`;
//   }
//   if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
//   if (page && page !== '') queryparams += `page=${page}&`;
//   if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

//   const url = `${BASE_URL}/getCompanyDocuments/?${queryparams}getCompanyDocument=${companyId}&search=${searchQuery}`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const getAdminCompanyDocumentUser = async (
//   filterValues,
//   page,
//   rowsPerPage,
//   order,
//   orderBy,
//   search,
//   company_id
// ) => {
//   const values = { ...filterValues };
//   let queryparams = '';
//   for (const [key, value] of Object.entries(values)) {
//     queryparams += `${key}=${value}&`;
//   }
//   if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
//   if (company_id && company_id !== '') queryparams += `company_id=${company_id}&`;
//   const url = `${BASE_URL}/getAdminCompanyDocumentUser/?page=${page}&page_size=${rowsPerPage}&${queryparams}search=${search}`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const updateAdminUserDocumentRecord = async (dataToSend, userCompanyDocumentId) => {
//   const companyId = Number(getCompanyId());
//   const url = `${BASE_URL}/updateAdminUserDocumentRecord?user_company_document_id=${userCompanyDocumentId}&company_id=${companyId}`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(dataToSend),
//   });
//   const data = await response.json();
//   return data;
// };

// export const getAllCompanies = async (id) => {
//   const url = `${BASE_URL}/getChildCompany?ParentCompanyId=${id}`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const deleteCompanyDocument = async (id, companyDocumentAutoId) => {
//   const url = `${BASE_URL}/deleteCompanyDocument?company_document_auto_id=${companyDocumentAutoId}`;
//   const response = await fetch(url, {
//     method: 'delete',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       company_id: id,
//     }),
//   });
//   const data = await response.json();
//   return data;
// };

// export const postProjectPriorityChange = async (id, priority, task) => {
//   const queryParams = new URLSearchParams({ priority_id: priority.priority_auto_id }).toString();
//   const url = `${BASE_URL}/updateStatus?${queryParams}`;
//   const bodyData = {
//     priority_id: priority.priority_auto_id,
//     proj_auto_id: task.proj_auto_id,
//   };
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyData),
//   });
//   const data = await response.json();
//   return data;
// };

// export const postProjectStatusChange = async (id, status, task) => {
//   const queryParams = new URLSearchParams({ status_id: status.project_status_auto_id }).toString();
//   const url = `${BASE_URL}/updateStatus?${queryParams}`;
//   const bodyData = {
//     status_id: status.project_status_auto_id,
//     proj_auto_id: task.proj_auto_id,
//   };
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyData),
//   });
//   const data = await response.json();
//   return data;
// };

// export const addCompanyAsset = async (dataToSend) => {
//   const url = `${BASE_URL}/itemrecord`;
//   const response = await fetch(url, {
//     method: 'post',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(dataToSend),
//   });
//   const data = await response.json();
//   return data;
// };

// export const getCompanyAsset = async (page, rowsPerPage, order, orderBy, searchQuery, companyId) => {
//   let queryparams = '';
//   if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
//   if (page && page !== '') queryparams += `page=${page}&`;
//   if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

//   const url = `${BASE_URL}/getItemRecord/?${queryparams}company_id=${companyId}&search=${searchQuery}`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const getAssetsActivity = async (assetsID) => {
//   const url = `${BASE_URL}/asset-activity/${assetsID}`;
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const updateCompanyAssets = async (dataToSend, companyAssetAutoId) => {
//   const url = `${BASE_URL}/updateitemrecord?item_auto_id=${companyAssetAutoId}`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(dataToSend),
//   });
//   const data = await response.json();
//   return data;
// };

// export const addAssetRecord = async (dataToSend) => {
//   const url = `${BASE_URL}/assets/assetrecord`;
//   const response = await fetch(url, {
//     method: 'post',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(dataToSend),
//   });
//   const data = await response.json();
//   return data;
// };

// export const getCompanyAssetRecord = async (
//   filterValues,
//   page,
//   rowsPerPage,
//   order,
//   orderBy,
//   searchQuery,
//   companyId,
//   csv = '',
//   query_params
// ) => {
//   const values = { ...filterValues };
//   let queryparams = '';
//   for (const [key, value] of Object.entries(values)) {
//     queryparams += `${key}=${value}&`;
//   }
//   if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
//   if (page && page !== '') queryparams += `page=${page}&`;
//   if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

//   const selected_query_params = query_params || '';

//   let url = `${BASE_URL}/assets/getassetrecord/?company_id=${companyId}&search=${searchQuery}&${queryparams}`;

//   if (csv !== '') {
//     url += `&${csv}`;
//   }

//   if (selected_query_params !== '') {
//     url += `&columns=${selected_query_params}`;
//   }

//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// export const updateAllAssetListRecord = async (dataToSend, companyAssetAutoId) => {
//   const url = `${BASE_URL}/assets/updateassetrecord?asset_auto_id=${companyAssetAutoId}`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(dataToSend),
//   });
//   const data = await response.json();
//   return data;
// };

export const getUserBreaksReport = async (
  size,
  page,
  order,
  orderBy,
  userid,
  search,
  dateRange,
  filterStatus,
  setbreakDuration,
  userTimeZone,
  csv = '',
  query_params
) => {
  const breakStatus = setbreakDuration || '';
  const today = new Date();
  const last30Days = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

  // function formatDate(date) {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }
  // const startDateRange = dateRange[0] ? dateToLocal(dateRange[0]) : last30Days;
  // const endDateRange = dateRange[1] ? dateToLocal(dateRange[1]) : today;

  const startDateRange = dateRange[0]
    ? localToUTCIsoStringStartDay(dateRange[0])
    : localToUTCIsoStringStartDay(last30Days);
  const endDateRange = dateRange[1] ? localToUTCIsoStringEndDay(dateRange[1]) : localToUTCIsoStringEndDay(today);

  const selected_query_params = query_params || '';

  // let queryparams = '';
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;

  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}`;
  } else if (csv !== '' || selected_query_params !== '') {
    queryparams += 'sortByDate=desc';
  }

  let url = `${BASE_URL}/getUserBreaksReport?user_time_zone=${userTimeZone}&company_id=${getCompanyId()}&assign_to=${userid}&break_duration=${breakStatus}&page_size=${size}&page=${page}&${queryparams}&search=${search}&start_date=${localToIsoStringStartDay(
    startDateRange
  )}&end_date=${localToIsoStringStartDay(endDateRange)}`;

  if (csv !== '') {
    url += `&${csv}`;
  }

  if (selected_query_params !== '') {
    url += `&columns=${selected_query_params}`;
  }

  if (filterStatus !== null) {
    url += `&${filterStatus}`;
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getServerTime = async () => {
  const url = `${BASE_URL}/time/current/zone?timeZone=UTC`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

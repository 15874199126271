// ----------------------------------------------------------------------

export default function Toolbar() {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {
          maxHeight: 40,
        },
      },
    },
  };
}

// routes
import { PATH_DASHBOARD, PATH_ADMIN } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { getRoleId } from '../../utils/utilities';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.webp`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  kanbanView: getIcon('dashboard-2-svgrepo-com'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  mailOpen: getIcon('mail-open-svgrepo-com'),
  role: getIcon('user-role-svgrepo-com'),
  permission: getIcon('permission-svgrepo-com'),
  userManagement: getIcon('user-group-svgrepo-com'),
  dashboard2: getIcon('dashboard-2-svgrepo-com'),
  mail2: getIcon('email-svgrepo-com'),
  companies: getIcon('company-group-agency-institution-svgrepo-com'),
  owners: getIcon('business-man-svgrepo-com'),
  general: getIcon('setting-2-svgrepo-com'),
};

// const sidebarConfig = () =>
//   getRoleId() === 10
//     ? [
//         {
//           items: [
//             // Super Admin
//             {
//               title: 'admin',
//               path: PATH_ADMIN.superAdmin.admin,
//               icon: ICONS.dashboard2,
//             },
//             {
//               title: 'super admins list',
//               path: PATH_ADMIN.superAdminsList.list,
//               icon: ICONS.userManagement,
//             },
//             {
//               title: 'email configurations',
//               path: PATH_ADMIN.emailConfigurations.email,
//               icon: ICONS.mail2,
//             },
//             {
//               title: 'email templates',
//               path: PATH_ADMIN.emailTemplates.templates,
//               icon: ICONS.mailOpen,
//             },
//             {
//               title: 'roles',
//               path: PATH_ADMIN.roleManagement.roles,
//               icon: ICONS.role,
//             },
//             {
//               title: 'user management',
//               path: PATH_ADMIN.userManagement.users,
//               icon: ICONS.userManagement,
//             },
//             {
//               title: 'Companies',
//               path: PATH_ADMIN.companyManagement.companies,
//               icon: ICONS.companies,
//             },
//             {
//               title: 'Owners',
//               path: PATH_ADMIN.ownerManagement.owners,
//               icon: ICONS.owners,
//             },
//           ],
//         },
//       ]
//     : [
//         {
//           items: [
//             {
//               title: 'dashboard',
//               path: PATH_DASHBOARD.general.app,
//               icon: ICONS.dashboard,
//             },

//             {
//               title: 'attendance',
//               path: PATH_DASHBOARD.attendance.root,
//               icon: ICONS.calendar,
//               children: (() => {
//                 const roleID = Number(getRoleId());
//                 if ([1, 3, 8, 7].includes(roleID)) {
//                   return [
//                     { title: 'updates', path: PATH_DASHBOARD.attendance.root },
//                     { title: 'office timings', path: PATH_DASHBOARD.attendance.timing },
//                     { title: 'log', path: PATH_DASHBOARD.attendance.log },
//                     { title: 'break', path: PATH_DASHBOARD.attendance.breakView },
//                     { title: 'Office days', path: PATH_DASHBOARD.attendance.userWorkdays },
//                     { title: 'Late sign-in', path: PATH_DASHBOARD.attendance.lateSignInApproval },
//                   ];
//                 }
//                 if (roleID === 11) {
//                   return [{ title: 'updates', path: PATH_DASHBOARD.attendance.root }];
//                 }
//                 return [
//                   { title: 'updates', path: PATH_DASHBOARD.attendance.root },
//                   { title: 'office timings', path: PATH_DASHBOARD.attendance.timing },
//                   { title: 'Office days', path: PATH_DASHBOARD.attendance.userWorkdays },
//                   { title: 'Late sign-in', path: PATH_DASHBOARD.attendance.lateSignInApproval },
//                 ];
//               })(),
//             },

//             {
//               title: 'company',
//               path: PATH_DASHBOARD.company.root,
//               icon: ICONS.banking,
//               children: [1, 3, 8].includes(Number(getRoleId()))
//                 ? [
//                     { title: 'Profile', path: PATH_DASHBOARD.company.root },
//                     { title: 'Documents', path: PATH_DASHBOARD.company.documents },
//                   ]
//                 : [{ title: 'Profile', path: PATH_DASHBOARD.company.root }],
//             },

//             {
//               title: 'projects',
//               path: PATH_DASHBOARD.projects.root,
//               icon: ICONS.analytics,
//               children: [
//                 { title: 'List', path: PATH_DASHBOARD.projects.list },
//                 { title: 'Cards', path: PATH_DASHBOARD.projects.root },
//               ],
//             },

//             {
//               title: 'tasks',
//               path: PATH_DASHBOARD.tasks.root,
//               icon: ICONS.kanban,
//               children: [
//                 { title: 'All', path: PATH_DASHBOARD.tasks.root },
//                 { title: 'My tasks', path: PATH_DASHBOARD.tasks.mytasklist },
//               ],
//             },

//             {
//               title: 'kanban',
//               path: PATH_DASHBOARD.kanban.board,
//               icon: ICONS.kanbanView,
//               children: [
//                 { title: 'All', path: PATH_DASHBOARD.kanban.board },
//                 { title: 'My tasks', path: PATH_DASHBOARD.kanban.mytaskskanban },
//               ],
//             },

//             {
//               title: 'timesheet',
//               path: PATH_DASHBOARD.timesheet.root,
//               icon: ICONS.booking,
//               children: [1, 3, 8, 7].includes(Number(getRoleId()))
//                 ? [
//                     { title: 'Weekly', path: PATH_DASHBOARD.timesheet.weekly },
//                     { title: 'List View', path: PATH_DASHBOARD.timesheet.list },
//                     { title: 'Missing Timesheet', path: PATH_DASHBOARD.timesheet.missingList },
//                     { title: 'Calendar view', path: PATH_DASHBOARD.timesheet.calendar },
//                   ]
//                 : [
//                     { title: 'Weekly', path: PATH_DASHBOARD.timesheet.weekly },
//                     { title: 'List View', path: PATH_DASHBOARD.timesheet.list },
//                     { title: 'Missing Timesheet', path: PATH_DASHBOARD.timesheet.missingListUser },
//                   ],
//             },
//             {
//               title: 'leaves',
//               path: PATH_DASHBOARD.leaves.root,
//               icon: ICONS.calendar,
//               children: [1, 3, 8].includes(Number(getRoleId()))
//                 ? [
//                     { title: 'all leaves', path: PATH_DASHBOARD.leaves.root },
//                     { title: 'my leaves', path: PATH_DASHBOARD.leaves.user },
//                     { title: 'management', path: PATH_DASHBOARD.leaves.management },
//                     { title: 'pending approvals', path: PATH_DASHBOARD.leaves.pendingApproval },
//                     { title: 'leave type', path: PATH_DASHBOARD.leaves.types },
//                     { title: 'holidays', path: PATH_DASHBOARD.leaves.holidays },
//                     { title: 'workdays', path: PATH_DASHBOARD.leaves.workDays },
//                     { title: 'schedule', path: PATH_DASHBOARD.leaves.schedule },
//                     { title: 'earning policy', path: PATH_DASHBOARD.leaves.earningPolicy },
//                     { title: 'approval workflow', path: PATH_DASHBOARD.leaves.approvalWorkflow },
//                   ]
//                 : [
//                     { title: 'all leaves', path: PATH_DASHBOARD.leaves.root },
//                     { title: 'my leaves', path: PATH_DASHBOARD.leaves.user },
//                     { title: 'management', path: PATH_DASHBOARD.leaves.management },
//                     { title: 'pending approvals', path: PATH_DASHBOARD.leaves.pendingApproval },
//                     { title: 'holidays', path: PATH_DASHBOARD.leaves.holidays },
//                     { title: 'schedule', path: PATH_DASHBOARD.leaves.schedule },
//                   ],
//             },
//             {
//               title: 'payroll',
//               path: PATH_DASHBOARD.payroll.salaryAdjustment,
//               icon: ICONS.ecommerce,
//               children: [
//                 {
//                   title: [1, 3, 8].includes(Number(getRoleId())) ? 'salary adjustment' : 'salary',
//                   path: PATH_DASHBOARD.payroll.salaryAdjustment,
//                 },
//               ],
//             },
//             {
//               title: 'team',
//               path: PATH_DASHBOARD.team.root,
//               icon: ICONS.chat,
//               children: [
//                 { title: 'member list', path: PATH_DASHBOARD.team.root },
//                 { title: 'user Documents', path: PATH_DASHBOARD.team.userDocuments },
//               ],
//             },

//             {
//               title: 'assets',
//               path: PATH_DASHBOARD.assets.root,
//               icon: ICONS.general,
//               children: [1, 3, 8].includes(Number(getRoleId()))
//                 ? [
//                     { title: 'types', path: PATH_DASHBOARD.assets.root },
//                     { title: 'all', path: PATH_DASHBOARD.assets.allAssetsList },
//                     { title: 'my assets', path: PATH_DASHBOARD.assets.myAssetsList },
//                   ]
//                 : [{ title: 'my assets', path: PATH_DASHBOARD.assets.myAssetsList }],
//             },

//             {
//               title: 'user',
//               path: PATH_DASHBOARD.user.view,
//               icon: ICONS.user,
//             },
//           ],
//         },
//       ];

const sidebarConfig = () => {
  const roleId = Number(getRoleId());

  if (roleId === 10) {
    return [
      {
        items: [
          // Super Admin
          {
            title: 'admin',
            path: PATH_ADMIN.superAdmin.admin,
            icon: ICONS.dashboard2,
          },
          {
            title: 'super admins list',
            path: PATH_ADMIN.superAdminsList.list,
            icon: ICONS.userManagement,
          },
          {
            title: 'email configurations',
            path: PATH_ADMIN.emailConfigurations.email,
            icon: ICONS.mail2,
          },
          {
            title: 'email templates',
            path: PATH_ADMIN.emailTemplates.templates,
            icon: ICONS.mailOpen,
          },
          {
            title: 'roles',
            path: PATH_ADMIN.roleManagement.roles,
            icon: ICONS.role,
          },
          {
            title: 'user management',
            path: PATH_ADMIN.userManagement.users,
            icon: ICONS.userManagement,
          },
          {
            title: 'Companies',
            path: PATH_ADMIN.companyManagement.companies,
            icon: ICONS.companies,
          },
          {
            title: 'Owners',
            path: PATH_ADMIN.ownerManagement.owners,
            icon: ICONS.owners,
          },
        ],
      },
    ];
  }

  if (roleId === 11) {
    return [
      {
        items: [
          {
            title: 'attendance',
            path: PATH_DASHBOARD.attendance.root,
            icon: ICONS.calendar,
          },
          {
            title: 'projects',
            path: PATH_DASHBOARD.projects.root,
            icon: ICONS.analytics,
            children: [
              { title: 'List', path: PATH_DASHBOARD.projects.list },
              { title: 'Cards', path: PATH_DASHBOARD.projects.root },
            ],
          },
          {
            title: 'tasks',
            path: PATH_DASHBOARD.tasks.root,
            icon: ICONS.kanban,
            children: [
              { title: 'All', path: PATH_DASHBOARD.tasks.root },
              { title: 'My tasks', path: PATH_DASHBOARD.tasks.mytasklist },
            ],
          },
          {
            title: 'kanban',
            path: PATH_DASHBOARD.kanban.board,
            icon: ICONS.kanbanView,
            children: [
              { title: 'All', path: PATH_DASHBOARD.kanban.board },
              { title: 'My tasks', path: PATH_DASHBOARD.kanban.mytaskskanban },
            ],
          },
          {
            title: 'user',
            path: PATH_DASHBOARD.user.view,
            icon: ICONS.user,
          },
        ],
      },
    ];
  }

  return [
    {
      items: [
        {
          title: 'dashboard',
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.dashboard,
        },

        {
          title: 'attendance',
          path: PATH_DASHBOARD.attendance.root,
          icon: ICONS.calendar,
          children: (() => {
            const roleID = Number(getRoleId());
            if ([1, 3, 8, 7].includes(roleID)) {
              return [
                { title: 'updates', path: PATH_DASHBOARD.attendance.root },
                { title: 'office timings', path: PATH_DASHBOARD.attendance.timing },
                { title: 'log', path: PATH_DASHBOARD.attendance.log },
                { title: 'break', path: PATH_DASHBOARD.attendance.breakView },
                { title: 'Office days', path: PATH_DASHBOARD.attendance.userWorkdays },
                { title: 'Late sign-in', path: PATH_DASHBOARD.attendance.lateSignInApproval },
              ];
            }
            if (roleID === 11) {
              return [{ title: 'updates', path: PATH_DASHBOARD.attendance.root }];
            }
            return [
              { title: 'updates', path: PATH_DASHBOARD.attendance.root },
              { title: 'office timings', path: PATH_DASHBOARD.attendance.timing },
              { title: 'Office days', path: PATH_DASHBOARD.attendance.userWorkdays },
              { title: 'Late sign-in', path: PATH_DASHBOARD.attendance.lateSignInApproval },
            ];
          })(),
        },

        {
          title: 'company',
          path: PATH_DASHBOARD.company.root,
          icon: ICONS.banking,
          children: [1, 3, 8].includes(Number(getRoleId()))
            ? [
                { title: 'Profile', path: PATH_DASHBOARD.company.root },
                { title: 'Documents', path: PATH_DASHBOARD.company.documents },
              ]
            : [{ title: 'Profile', path: PATH_DASHBOARD.company.root }],
        },

        {
          title: 'projects',
          path: PATH_DASHBOARD.projects.root,
          icon: ICONS.analytics,
          children: [
            { title: 'List', path: PATH_DASHBOARD.projects.list },
            { title: 'Cards', path: PATH_DASHBOARD.projects.root },
          ],
        },

        {
          title: 'tasks',
          path: PATH_DASHBOARD.tasks.root,
          icon: ICONS.kanban,
          children: [
            { title: 'All', path: PATH_DASHBOARD.tasks.root },
            { title: 'My tasks', path: PATH_DASHBOARD.tasks.mytasklist },
          ],
        },

        {
          title: 'kanban',
          path: PATH_DASHBOARD.kanban.board,
          icon: ICONS.kanbanView,
          children: [
            { title: 'All', path: PATH_DASHBOARD.kanban.board },
            { title: 'My tasks', path: PATH_DASHBOARD.kanban.mytaskskanban },
          ],
        },

        {
          title: 'timesheet',
          path: PATH_DASHBOARD.timesheet.root,
          icon: ICONS.booking,
          children: [1, 3, 8, 7].includes(Number(getRoleId()))
            ? [
                { title: 'Weekly', path: PATH_DASHBOARD.timesheet.weekly },
                { title: 'List View', path: PATH_DASHBOARD.timesheet.list },
                { title: 'Missing Timesheet', path: PATH_DASHBOARD.timesheet.missingList },
                { title: 'Calendar view', path: PATH_DASHBOARD.timesheet.calendar },
              ]
            : [
                { title: 'Weekly', path: PATH_DASHBOARD.timesheet.weekly },
                { title: 'List View', path: PATH_DASHBOARD.timesheet.list },
                { title: 'Missing Timesheet', path: PATH_DASHBOARD.timesheet.missingList },
              ],
        },
        {
          title: 'leaves',
          path: PATH_DASHBOARD.leaves.root,
          icon: ICONS.calendar,
          children: [1, 3, 8].includes(Number(getRoleId()))
            ? [
                { title: 'all leaves', path: PATH_DASHBOARD.leaves.root },
                { title: 'my leaves', path: PATH_DASHBOARD.leaves.user },
                { title: 'management', path: PATH_DASHBOARD.leaves.management },
                { title: 'pending approvals', path: PATH_DASHBOARD.leaves.pendingApproval },
                { title: 'leave type', path: PATH_DASHBOARD.leaves.types },
                { title: 'holidays', path: PATH_DASHBOARD.leaves.holidays },
                { title: 'workdays', path: PATH_DASHBOARD.leaves.workDays },
                { title: 'schedule', path: PATH_DASHBOARD.leaves.schedule },
                { title: 'earning policy', path: PATH_DASHBOARD.leaves.earningPolicy },
                { title: 'approval workflow', path: PATH_DASHBOARD.leaves.approvalWorkflow },
              ]
            : [
                { title: 'all leaves', path: PATH_DASHBOARD.leaves.root },
                { title: 'my leaves', path: PATH_DASHBOARD.leaves.user },
                { title: 'management', path: PATH_DASHBOARD.leaves.management },
                { title: 'pending approvals', path: PATH_DASHBOARD.leaves.pendingApproval },
                { title: 'holidays', path: PATH_DASHBOARD.leaves.holidays },
                { title: 'schedule', path: PATH_DASHBOARD.leaves.schedule },
              ],
        },
        {
          title: 'payroll',
          path: PATH_DASHBOARD.payroll.salaryAdjustment,
          icon: ICONS.ecommerce,
          children: [
            {
              title: [1, 3, 8].includes(Number(getRoleId())) ? 'salary adjustment' : 'salary',
              path: PATH_DASHBOARD.payroll.salaryAdjustment,
            },
          ],
        },
        {
          title: 'team',
          path: PATH_DASHBOARD.team.root,
          icon: ICONS.chat,
          children: [
            { title: 'member list', path: PATH_DASHBOARD.team.root },
            { title: 'user Documents', path: PATH_DASHBOARD.team.userDocuments },
          ],
        },

        {
          title: 'assets',
          path: PATH_DASHBOARD.assets.root,
          icon: ICONS.general,
          children: [1, 3, 8].includes(Number(getRoleId()))
            ? [
                { title: 'types', path: PATH_DASHBOARD.assets.root },
                { title: 'all', path: PATH_DASHBOARD.assets.allAssetsList },
                { title: 'my assets', path: PATH_DASHBOARD.assets.myAssetsList },
              ]
            : [{ title: 'my assets', path: PATH_DASHBOARD.assets.myAssetsList }],
        },

        {
          title: 'user',
          path: PATH_DASHBOARD.user.view,
          icon: ICONS.user,
        },
      ],
    },
  ];
};

export default sidebarConfig;

// ----------------------------------------------------------------------

export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            // height: 40,
            fontSize: 14,
          },
          '& .MuiInputLabel-root': {
            fontSize: 14,
            lineHeight: 1,
            marginTop: -3,
            backgroundColor: 'transparent !important',
          },
          '& .MuiInputBase-input': {
            paddingTop: 9,
            paddingBottom: 10,
          },
          '& .Mui-disabled': {
            '-webkit-text-fill-color':
              theme.palette.mode === 'light'
                ? `${theme.palette.grey[700]} !important`
                : `${theme.palette.grey[300]} !important`,
            backgroundColor: theme.palette.grey[200],
            color: '#919EAB',
          },
          '& MuiInputLabel-root.Mui-disabled': {
            '-webkit-text-fill-color':
              theme.palette.mode === 'light'
                ? `${theme.palette.grey[700]} !important`
                : `${theme.palette.grey[300]} !important`,
          },
        },
      },
    },
  };
}

import { memo } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// Constants
const SCROLLBAR_TIMEOUT = 500;
const MOBILE_REGEX = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
const VERTICAL_TRACK_WIDTH = 10;
const HORIZONTAL_SCROLLBAR_HEIGHT = 6;

// Styled components
const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: VERTICAL_TRACK_WIDTH,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: HORIZONTAL_SCROLLBAR_HEIGHT,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

// Memoized device detection
const isMobileDevice = () => MOBILE_REGEX.test(navigator.userAgent);

const Scrollbar = ({ children, sx, ...other }) => {
  const isMobile = isMobileDevice();

  if (isMobile) {
    return (
      <Box
        sx={{
          overflowX: 'auto',
          WebkitOverflowScrolling: 'touch', // Improve mobile scrolling
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={SCROLLBAR_TIMEOUT} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default memo(Scrollbar);

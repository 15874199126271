import { BASE_URL, jwtToken } from './server';

export const getOwnersAdmin = async (page, pageSize, search, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  const response = await fetch(
    `${BASE_URL}/admingetusers?page=${page}&page_size=${pageSize}&all_company_owners=${3}&search=${search}&${queryparams}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const getUsersAdmin = async (page, pageSize, search, order, orderBy) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  const response = await fetch(
    `${BASE_URL}/admingetusers?page=${page}&page_size=${pageSize}&all_users=${2}&search=${search}&${queryparams}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const GetManagers = async () => {
  const url = `${BASE_URL}/getFunctionalProjectManagers`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsersSuperAdmin = async (page, pageSize, search) => {
  const url = `${BASE_URL}/getAllCompanyUser?page=${page}&page_size=${pageSize}&search=${search}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const addSuperAdmin = async (dataToSend) => {
  const url = `${BASE_URL}/addSuperAdmin?add=1`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getAllSuperAdmin = async (page, pageSize, search, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getAllSuperAdmin?page=${page}&page_size=${pageSize}&search=${search}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteSuperAdmin = async (userId, userCompanyId) => {
  const dataToSend = {
    user_id: userId,
    company_id: userCompanyId,
    super_admin: 0,
    super_admin_active: 0,
  };
  const url = `${BASE_URL}/addSuperAdmin?delete=1`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const enableSuperAdmin = async (id, flag, userCompanyId, superAdminExist) => {
  const dataToSend = {
    user_id: id,
    super_admin_active: flag,
    super_admin: superAdminExist,
    company_id: userCompanyId,
  };
  const url = `${BASE_URL}/addSuperAdmin?disable=1`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getCheckSuperAdmin = async () => {
  const url = `${BASE_URL}/getCheckSuperAdmin`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// import { toZonedTime } from 'date-fns-tz';

// export const localToUTC = (localDate) => {
//   const currentUtcISOString = new Date(localDate).toISOString();
//   return currentUtcISOString;
// };

// export const UTCToLocal = (utcDate) => {
//   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const localDate = toZonedTime(utcDate, userTimeZone);
//   return localDate;
// };

import { format, startOfDay } from 'date-fns';
import moment from 'moment';
import 'moment-timezone';

export const localToUTCIsoString = (localDate) => {
  const UTCDate = moment.utc(new Date(localDate)).toISOString();
  return UTCDate;
};

// for star date of the form
export const localToUTCIsoStringStartDay = (localDate) => {
  const date = new Date(localDate);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  const UTCDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
  return UTCDate;
};

export const localToIsoStringStartDay = (localDate) => {
  const date = new Date(localDate);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  const UTCDate = moment(date).format('YYYY-MM-DD');
  return UTCDate;
};

// for end date of the form
export const localToUTCIsoStringEndDay = (localDate) => {
  const date = new Date(localDate);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  const UTCDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
  return UTCDate;
};

export const UTCToLocal = (utcDate, startDay) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = moment.tz(new Date(utcDate), userTimeZone);
  if (startDay) {
    return localDate.startOf('day');
  }
  return localDate;
};

export const dateToLocal = (utcDate, startDay) => {
  const localDate = moment(utcDate);
  if (startDay) {
    return localDate.startOf('day');
  }
  return localDate;
};

export const dateToLocalDateFNS = (utcDate, startDay) => {
  let date = utcDate;
  if (date) {
    if (typeof date === 'object') {
      date = format(utcDate, 'yyyy-MM-dd');
    }
    console.log({ date }, typeof date);
    const [year, month, day, hour = '00', minute = '00', second = '00'] = date
      .replace('Z', '') // Remove the trailing 'Z' if present
      .split(/[-T:]/);

    const localDate = new Date(
      Number(year),
      Number(month) - 1, // Month is 0-indexed
      Number(day),
      Number(hour),
      Number(minute),
      Number(second)
    );

    if (startDay) {
      return startOfDay(localDate); // Set the time to the start of the day
    }
    return localDate;
  }
  return date;
};

export const formatDate = (date, format) => {
  const formatDate = moment(new Date(date)).format(format);
  return formatDate;
};

export function formatAMPM(date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let ampm;

  if (hours >= 12) {
    ampm = 'PM';
    if (hours > 12) {
      hours -= 12;
    }
  } else {
    ampm = 'AM';
  }

  if (hours === 0) {
    hours = 12;
  }

  let minutesStr;
  if (minutes < 10) {
    minutesStr = `0${minutes}`;
  } else {
    minutesStr = minutes;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }

  return `${hours}:${minutesStr} ${ampm}`;
}

export const formatDateWithoutUTCTime = (date, formatDate) => moment(date).format(formatDate);

function parseDate(dateString) {
  // Example: "Fri Nov 17 2023 00:52:13 GMT+0500 (Pakistan Standard Time)"
  if (typeof dateString === 'string') {
    return new Date(dateString);
  }

  // Example: "16/11/2023, 14:02:11"
  if (typeof dateString === 'string' && dateString.includes(',')) {
    const [day, month, year, time] = dateString.split(/[\s,/:]+/);
    const [hours, minutes, seconds] = time.split(':').map(Number);

    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  }

  return new Date(); // Default to the current date if parsing fails
}

export function localToUTC(localDate) {
  if (!localDate) {
    return null;
  }

  const parsedDate = parseDate(localDate);

  if (Number.isNaN(parsedDate.getTime())) {
    return null;
  }

  const year = parsedDate.getUTCFullYear();
  const month = String(parsedDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(parsedDate.getUTCDate()).padStart(2, '0');
  const hours = String(parsedDate.getUTCHours()).padStart(2, '0');
  const minutes = String(parsedDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(parsedDate.getUTCSeconds()).padStart(2, '0');

  const utcString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

  return utcString;
}

export function subDays(upDate) {
  const updateDate = dateToLocal(upDate);
  const n = moment(updateDate);
  const d = moment(n, 'YYYY-MM-DD HH:mm').fromNow();
  return d;
}

export function isSameOrBefore(startDate, endDate) {
  const startDateTmp = new Date(startDate);
  startDateTmp.setHours(0);
  startDateTmp.setMinutes(0);
  startDateTmp.setSeconds(0);
  startDateTmp.setMilliseconds(0);
  const endDateTmp = new Date(endDate);
  endDateTmp.setHours(0);
  endDateTmp.setMinutes(0);
  endDateTmp.setSeconds(0);
  endDateTmp.setMilliseconds(0);
  return moment(startDateTmp).isSameOrBefore(endDateTmp);
}

export function isAfter(startDate, endDate) {
  const startDateTmp = new Date(startDate);
  startDateTmp.setHours(0);
  startDateTmp.setMinutes(0);
  startDateTmp.setSeconds(0);
  startDateTmp.setMilliseconds(0);
  const endDateTmp = new Date(endDate);
  endDateTmp.setHours(0);
  endDateTmp.setMinutes(0);
  endDateTmp.setSeconds(0);
  endDateTmp.setMilliseconds(0);

  return moment(startDateTmp).isAfter(endDate ? endDateTmp : endDate);
}

export function isBefore(startDate, endDate) {
  return moment(new Date(startDate)).isBefore(new Date(endDate));
}

export function addDate(date, days, months, years) {
  return moment(date).add(days, 'day').add(months, 'M').add(years, 'y');
}

// for convert local time to UTC before send request to server. like 20:00:00
export function convertTimeToUTC(time) {
  const newTime = `${time.substring(0, 5)}:00`;

  const date = new Date();
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = newTime.split(':').map(Number);

  // Set the time on the date object
  date.setHours(hours, minutes, seconds);
  return localToUTCIsoString(date).split('T')[1].substring(0, 8);
}

// 20:00:00 for 24 hours time
export function convertTimeToLocal(time) {
  const sourceTimezone = 'UTC';
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const sourceTime = moment.tz(time, 'HH:mm:ss', sourceTimezone);

  const localTime = sourceTime.clone().tz(userTimeZone);
  return localTime.format('HH:mm:ss');
}

// for 12:10 AM for 12 hours time
export function convertTimeToLocal12Hour(time) {
  const sourceTimezone = 'UTC';
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const sourceTime = moment.tz(time, 'HH:mm', sourceTimezone);
  const localTime = sourceTime.clone().tz(userTimeZone);
  return localTime.format('hh:mm A');
}

export const FORMAT_LLL = 'lll'; // Dec 12, 2024 5:17 PM
export const FORMAT_DDDD = 'dddd'; // dddd
export const FORMAT_MM_DD_YYYY = 'MMM DD, YYYY'; // Dec 02, 2024
export const FORMAT_MM_DO = 'MMM Do'; // Dec 12th
export const FORMAT_LL = 'll';

export const FORMAT_LLL__DD_YYYY_FNS = 'LLL dd, yyyy';

import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'; // useless
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import superAdminIcon from '@iconify/icons-eva/person-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import { useZustand } from 'use-zustand';
import {
  getRoleId,
  setUserEmail,
  setUserName,
  setUserSession,
  setGlobalProjectId,
  setCompanyName,
  setIsOwner,
  setUserState,
  setRoleId,
  setUser,
  setCompanyId,
  setUserCountry,
  setUserId,
  setUserCity,
  setCountryCodeIso,
  setProjectExist,
  setJwtToken,
  setAccessTokenForSuperAdmin,
  setJwtTokenForSuperAdmin,
  getUserId,
  setUserIdSuperAdmin,
  getUserIdSuperAdmin,
  setGlobalProjectName,
} from '../utils/utilities';
import { setSession } from '../utils/jwt';
import { getCheckSuperAdmin } from '../server/SuperAdminConfigurationApi';
import { getReverseUser } from '../server/UserApi';

import CustomConfirm from './CustomConfirm';
import useChangedStore from '../hooks/useZustand';

// ----------------------------------------------------------------------
const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary,
  })
);

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 46,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object,
  index: PropTypes.number,
  openChild: PropTypes.number, // Add this line
  setOpenChild: PropTypes.func, // Add this line
  pendingApproval: PropTypes.number,
};

function NavItem({ item, active, isShow, index, openChild, setOpenChild, pendingApproval }) {
  const theme = useTheme();
  const isChanged = useZustand(useChangedStore, (state) => state.isChanged);
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  // const navigate = useNavigate();
  const [nextPath, setNextPath] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpen = (num) => {
    setOpenChild(openChild !== num ? num : null);
  };

  const routingAccess = (event, url) => {
    if (isChanged) {
      event.preventDefault();
      setNextPath(url);
      setIsOpenModal(true);
    }
  };

  let pathArray = [];
  if (Number(getRoleId()) === 10) {
    pathArray = [
      pathname.includes('/charts'),
      pathname.includes('/superadminlist'),
      pathname.includes('/email-configuration'),
      pathname.includes('/email-templates'),
      pathname.includes('/role-management'),
      // pathname.includes('/permission-management'),
      pathname.includes('/user-management'),
      pathname.includes('/company-management'),
      pathname.includes('/owner-management'),
      // pathname.includes('/general-settings')
    ];
  } else if (Number(getRoleId()) === 11) {
    pathArray = [
      pathname.includes('/attendance'),
      pathname.includes('/projects'),
      pathname.includes('/tasks'),
      pathname.includes('/kanban'),
      pathname.includes('/user'),
    ];
  } else {
    pathArray = [
      pathname.includes('/app'),
      pathname.includes('/attendance'),
      pathname.includes('/company'),
      pathname.includes('/projects'),
      pathname.includes('/tasks'),
      pathname.includes('/kanban'),
      pathname.includes('/timesheet'),
      pathname.includes('/leaves'),
      pathname.includes('/payroll'),
      pathname.includes('/team'),
      pathname.includes('/assets'),
      pathname.includes('/user'),
    ];
  }

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={() => handleOpen(index)}
          sx={{
            ...(pathArray[index] && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                // onClick={(e) => routingAccess(e, path)}
                icon={openChild === index ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={openChild === index} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item, ID) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  <>
                    <ListItemStyle
                      key={`list-item-style-${ID}`} 
                      component={RouterLink}
                      to={path}
                      onClick={(e) => routingAccess(e, path)}
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                      }}
                    >
                      <ListItemIconStyle>
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.disabled',
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: 'primary.main',
                            }),
                          }}
                        />
                      </ListItemIconStyle>
                      <ListItemText
                        disableTypography
                        primary={title === 'pending approvals' ? `${title} (${pendingApproval})` : title}
                      />
                    </ListItemStyle>
                    <CustomConfirm open={isOpenModal} setOpen={setIsOpenModal} nextPath={nextPath} />
                    {/* <CustomConfirm
                      open={isOpenModal}
                      setOpen={() => {
                        handleConfirm();
                      }}
                    /> */}
                  </>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <>
      <ListItemStyle
        component={RouterLink}
        to={path}
        // href={to}
        onClick={(e) => routingAccess(e, path)}
        // target="_blank"
        // rel="noopener"
        sx={{
          ...(pathArray[index] && activeRootStyle),
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        {isShow && (
          <>
            <ListItemText disableTypography primary={title} />
            {info && info}
          </>
        )}
      </ListItemStyle>
      {/* <CustomConfirm
        open={isOpenModal}
        setOpen={() => {
          handleConfirm();
        }}
      /> */}
      <CustomConfirm open={isOpenModal} setOpen={setIsOpenModal} nextPath={nextPath} />
    </>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array,
  setPendingApproval: PropTypes.func,
  pendingApproval: PropTypes.number,
  openChild: PropTypes.number,
  isCollapse: PropTypes.any,
};

export default function NavSection({
  isCollapse,
  pendingApproval,
  setPendingApproval,
  navConfig,
  isShow = true,
  ...other
}) {
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const [openChild, setOpenChild] = useState();
  const userToSuperAdmin = getUserId();
  const superAdminToUser = getUserIdSuperAdmin();

  const roles = [1, 2, 3, 7, 8, 9];
  const superAdminRole = [10];

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={`list-${list.id}`} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item, i) => (
              <NavItem
                item={item}
                key={`nav-item-${i}`} 
                active={match}
                isShow={isShow}
                index={i}
                openChild={openChild}
                setOpenChild={setOpenChild}
                showapproval={other.showapproval}
                pendingApproval={pendingApproval}
                setPendingApproval={setPendingApproval}
              />
            ))}
          </List>
        );
      })}
      {isCollapse ? (
        <>
          {(roles.includes(Number(getRoleId())) &&
            localStorage.getItem('sd_key_SA') === '1' && localStorage.getItem('sd_key_SA_Active') === '1') ? (
            <Button
              onClick={() =>
                getCheckSuperAdmin().then((res) => {
                  Navigate('/loading');
                  if (!res.Message) {
                    let accessToken = null;
                    accessToken = res.jwt;
                    const user = {
                      id: res.data[0]?.id,
                      displayName: `${res.data[0]?.user_first_name} ${res.data[0]?.user_last_name}`,
                      email: res.data[0]?.user_username,
                      password: 'demo1234',
                      photoURL: res.data[0]?.user_profile_img,
                      phoneNumber: '+40 777666555',
                      country: res.data[0]?.user_country,
                      address: '90210 Broadway Blvd',
                      state: 'California',
                      city: 'San Francisco',
                      zipCode: '94116',
                      about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
                      // role: res.userRole[0].role_name,
                      role: res.data[0]?.role_id,
                      // isPublic: true
                    };
                    setUser(user);
                    localStorage.setItem('i18nextLng', 'en');
                    setIsOwner(res.data[0]?.user_is_owner);
                    setUserId(res.data[0]?.id);
                    setRoleId(res.data[0]?.role_id);
                    setUserCountry(res?.data[0].user_country);
                    setUserCity(res?.data[0].user_city);
                    setUserState(res?.data[0].user_state);
                    setCountryCodeIso(res?.data[0].user_country_iso2);
                    setJwtTokenForSuperAdmin(res.jwt);
                    setUserName(`${res.data[0]?.user_first_name} ${res.data[0]?.user_last_name}`);
                    setUserEmail(res.data[0]?.user_username);
                    setAccessTokenForSuperAdmin(accessToken);
                    setSession(accessToken);
                    setUserIdSuperAdmin(userToSuperAdmin);
                    window.location.replace('/admin/charts');
                  }
                })
              }
              style={{ marginLeft: '27px', color: '#637381' }}
            >
              <Icon icon={superAdminIcon} style={{ marginRight: '17px', fontSize: '22px' }} />
            </Button>
          ) : null}
          {(superAdminRole.includes(Number(getRoleId()))) && localStorage.getItem('sd_key_20') &&
            localStorage.getItem('sd_key_SA') === '1' && localStorage.getItem('sd_key_SA_Active') === '1' ? (
            <Button
              onClick={() =>
                getReverseUser(superAdminToUser).then((res) => {
                  Navigate('/loading');
                  if (!res.Message) {
                    let accessToken = null;
                    accessToken = res.token;
                    const user = {
                      id: res.userData?.id,
                      displayName: `${res.userData?.user_first_name} ${res.userData?.user_last_name}`,
                      email: res.userData?.user_username,
                      password: 'demo1234',
                      photoURL: res.userData?.user_profile_img,
                      phoneNumber: '+40 777666555',
                      country: res.userData?.user_country,
                      address: '90210 Broadway Blvd',
                      state: 'California',
                      city: 'San Francisco',
                      zipCode: '94116',
                      about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
                      role: res.userRole[0].role_name,
                      isPublic: true,
                    };
                    setUser(user);
                    localStorage.setItem('i18nextLng', 'en');
                    setUserSession(moment());
                    // if (res?.projectID?.length) {
                    //   setProjectExist(1);
                    //   console.log(res?.projectID[0]);
                    //   setGlobalProjectId(res?.projectID[0]?.proj_auto_id);
                    //   setGlobalProjectName(res?.projectID[0]?.proj_name);
                    // } else {
                    //   setProjectExist(0);
                    // }
                    if (res?.userProject) {
                      setProjectExist(1);
                      setGlobalProjectId(res?.userProject?.proj_auto_id);
                      setGlobalProjectName(res?.userProject?.proj_name);
                    } else {
                      setProjectExist(0);
                    }
                    if (res.userCompany[0]) {
                      setCompanyId(res.userCompany[0].company_auto_id);
                      setCompanyName(res?.userCompany[0].company_name);
                    }
                    setIsOwner(res.userData?.user_is_owner);
                    setUserId(res.userData?.id);
                    setRoleId(res.userData?.role_id);
                    setUserCountry(res?.userData.user_country);
                    setUserCity(res?.userData.user_city);
                    setUserState(res?.userData.user_state);
                    setCountryCodeIso(res?.userData.user_country_iso2);
                    setJwtToken(res.token);
                    localStorage.setItem('sd_key_SA', res.userData.super_admin);
                    localStorage.setItem('sd_key_SA_Active', res.userData.super_admin_active);
                    setUserName(`${res.userData?.user_first_name} ${res.userData?.user_last_name}`);
                    setUserEmail(res.userData?.user_username);
                    setSession(accessToken);
                    window.location.replace('/dashboard/attendance/view');
                  }
                })
              }
              style={{ marginLeft: '28px', color: '#637381' }}
            >
              <Icon icon={superAdminIcon} style={{ marginRight: '20px', fontSize: '30px' }} />
            </Button>
          )
            : null}
        </>
      ) : (
        <>
          {(roles.includes(Number(getRoleId())) &&
            localStorage.getItem('sd_key_SA') === '1' && localStorage.getItem('sd_key_SA_Active') === '1') ? (
            <Button
              onClick={() =>
                getCheckSuperAdmin().then((res) => {
                  Navigate('/loading');
                  if (!res.Message) {
                    let accessToken = null;
                    accessToken = res.jwt;
                    const user = {
                      id: res.data[0]?.id,
                      displayName: `${res.data[0]?.user_first_name} ${res.data[0]?.user_last_name}`,
                      email: res.data[0]?.user_username,
                      password: 'demo1234',
                      photoURL: res.data[0]?.user_profile_img,
                      phoneNumber: '+40 777666555',
                      country: res.data[0]?.user_country,
                      address: '90210 Broadway Blvd',
                      state: 'California',
                      city: 'San Francisco',
                      zipCode: '94116',
                      about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
                      // role: res.userRole[0].role_name,
                      role: res.data[0]?.role_id,
                      // isPublic: true
                    };
                    setUser(user);
                    localStorage.setItem('i18nextLng', 'en');
                    setIsOwner(res.data[0]?.user_is_owner);
                    setUserId(res.data[0]?.id);
                    setRoleId(res.data[0]?.role_id);
                    setUserCountry(res?.data[0].user_country);
                    setUserCity(res?.data[0].user_city);
                    setUserState(res?.data[0].user_state);
                    setCountryCodeIso(res?.data[0].user_country_iso2);
                    setJwtTokenForSuperAdmin(res.jwt);
                    setUserName(`${res.data[0]?.user_first_name} ${res.data[0]?.user_last_name}`);
                    setUserEmail(res.data[0]?.user_username);
                    setAccessTokenForSuperAdmin(accessToken);
                    setSession(accessToken);
                    setUserIdSuperAdmin(userToSuperAdmin);
                    window.location.replace('/admin/charts');
                  }
                })
              }
              style={{ marginLeft: '33px', color: '#637381' }}
            >
              <Icon icon={superAdminIcon} style={{ marginRight: '17px', fontSize: '21px' }} />
              Super Admin
            </Button>
          ) : null}
          {(superAdminRole.includes(Number(getRoleId()))
            && localStorage.getItem('sd_key_20') &&
            localStorage.getItem('sd_key_SA') === '1' && localStorage.getItem('sd_key_SA_Active') === '1') ? (
            <Button
              onClick={() =>
                getReverseUser(superAdminToUser).then((res) => {
                  Navigate('/loading');
                  if (!res.Message) {
                    let accessToken = null;
                    accessToken = res.token;
                    const user = {
                      id: res.userData?.id,
                      displayName: `${res.userData?.user_first_name} ${res.userData?.user_last_name}`,
                      email: res.userData?.user_username,
                      password: 'demo1234',
                      photoURL: res.userData?.user_profile_img,
                      phoneNumber: '+40 777666555',
                      country: res.userData?.user_country,
                      address: '90210 Broadway Blvd',
                      state: 'California',
                      city: 'San Francisco',
                      zipCode: '94116',
                      about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
                      role: res.userRole[0].role_name,
                      isPublic: true,
                    };
                    setUser(user);
                    localStorage.setItem('i18nextLng', 'en');
                    setUserSession(moment());
                    // if (res?.projectID?.length) {
                    //   setProjectExist(1);
                    //   setGlobalProjectId(res?.projectID[0]?.proj_auto_id);
                    // } else {
                    //   setProjectExist(0);
                    // }
                    if (res?.userProject) {
                      setProjectExist(1);
                      setGlobalProjectId(res?.userProject?.proj_auto_id);
                    } else {
                      setProjectExist(0);
                    }
                    if (res.userCompany[0]) {
                      setCompanyId(res.userCompany[0].company_auto_id);
                      setCompanyName(res?.userCompany[0].company_name);
                    }
                    setIsOwner(res.userData?.user_is_owner);
                    setUserId(res.userData?.id);
                    setRoleId(res.userData?.role_id);
                    setUserCountry(res?.userData.user_country);
                    setUserCity(res?.userData.user_city);
                    setUserState(res?.userData.user_state);
                    setCountryCodeIso(res?.userData.user_country_iso2);
                    setJwtToken(res.token);
                    localStorage.setItem('sd_key_SA', res.userData.super_admin);
                    localStorage.setItem('sd_key_SA_Active', res.userData.super_admin_active);
                    setUserName(`${res.userData?.user_first_name} ${res.userData?.user_last_name}`);
                    setUserEmail(res.userData?.user_username);
                    setSession(accessToken);
                    window.location.replace('/dashboard/attendance/view');
                  }
                })
              }
              style={{ marginLeft: '30px', color: '#637381' }}
            >
              <Icon icon={superAdminIcon} style={{ marginRight: '20px', fontSize: '21px' }} />
              Main Account
            </Button>
          )
            : null}
        </>
      )}
    </Box>
  );
}

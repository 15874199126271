import { getCompanyId, getUserSession, getUserId, getRoleId } from '../utils/utilities';
import { BASE_URL, jwtToken } from './server';
import { localToIsoStringStartDay } from '../utils/dates-conversion';

// Get All Company Leaves
export const getAllCompanyLeaves = async (page, size, filterValues, order, orderBy, search, year, filterStatus) => {
  // ?approved=1&pending=0&page=1&page_size=25
  const memberStatus = filterStatus || '';
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const filter = filterValues;

  if (filterValues?.start_date) {
    filter.start_date = localToIsoStringStartDay(filterValues?.start_date);
  }

  if (filterValues?.end_date) {
    filter.end_date = localToIsoStringStartDay(filterValues?.end_date);
  }

  Object.entries(filter).forEach(([key, value]) => {
    queryparams += `${key}=${value}&`;
  });

  const url = `${BASE_URL}/getAllCompanyLeaves/${Number(
    `${getCompanyId()}`
  )}/company/${year}?page=${page}&page_size=${size}&${queryparams}&search=${search}&${memberStatus}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get Earn Leave By User
export const getEarnLeaveByUser = async (id, leaveId) => {
  // const info = id ? id : Number(localStorage.getItem('userId'));
  const url = `${BASE_URL}/earnLeaveByUser/${id}/user/${`${getUserSession().slice(0, 4)}`}/year/${leaveId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get Company Leave Plan
export const getCompanyLeavePlan = async (search, page, rowsPerPage, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}&`;
  }
  let url = `${BASE_URL}/getCompanyLeavePlan/${getCompanyId()}?page=${page}&page_size=${rowsPerPage}&${queryparams}`;
  if (search) {
    url += `&search=${search}`;
  }
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get Delete Company Leave Planner
export const deleteCompanyLeavePlanner = async (id) => {
  const url = `${BASE_URL}/deleteCompanyLeavePlannerType`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      comp_leave_planner_id: id,
      company_id: Number(`${getCompanyId()}`),

      flag: 0,
    }),
  });
  const data = await response.json();
  return data;
};

// Get Leaves for Approval
export const getLeaveForApprovals = async (page, size, filterValues, search, year, filterStatus, order, orderBy) => {
  const memberStatus = filterStatus || '';
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (filterValues?.start_date) filterValues.start_date = localToIsoStringStartDay(filterValues?.start_date);
  if (filterValues?.end_date) filterValues.end_date = localToIsoStringStartDay(filterValues?.end_date);

  Object.entries(filterValues).forEach(([key, value]) => {
    queryparams += `${key}=${value}&`;
  });

  const url = `${BASE_URL}/getLeaveForApprovals/${year}?page=${page}&page_size=${size}&${queryparams}&search=${search}&${memberStatus}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get my Leaves
export const getMyLeaves = async (page, size, order, orderBy, year) => {
  // ?approved=1&pending=0&page=1&page_size=25
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getAllCompanyLeaves/${Number(`${getCompanyId()}`)}/company/${year}?user_id=${Number(
    `${getUserId()}`
  )}&page=${page}&page_size=${size}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get User Leaves
export const getUserLeaves = async (year) => {
  const url = `${BASE_URL}/getUserLeaves/${Number(`${getCompanyId()}`)}/company/${year}?user_id=${Number(
    `${getUserId()}`
  )}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get User Entitlement
export const getUserEntitlement = async (id) => {
  const url = `${BASE_URL}/getUserEntitlement/${id}/user/${Number(getUserSession().slice(0, 4))}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Post Leave by User
export const postLeaveApplyByUser = async (info, values) => {
  const bodyDate = {
    user_id: info?.user_id ? info?.user_id : Number(`${getUserId()}`),
    company_id: Number(`${getCompanyId()}`),
    leave_type_name: values?.leaveType.leave_type_name,
    comp_leave_planner_id: values?.leaveType.comp_leave_planner_id,
    start_date: localToIsoStringStartDay(info?.start_date),
    end_date: localToIsoStringStartDay(info?.end_date),
    day: info?.days,
    balance: info?.balance,
    // entitlement_id: values?.user?.entitlement_id,
    entitlement_id: info?.entitlement_id,
    session: info?.session_name,
    reason: values?.reason,
    show_reason_flag: values?.showReason ? 1 : 0,
    emergency_flag: values?.emergencyLeave ? 1 : 0,
  };

  const url = `${BASE_URL}/leaveApplyByUser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const postCompanyLeavePlanner = async (info) => {
  const bodyDate = {
    company_id: Number(`${getCompanyId()}`),
    user_id: Number(`${getUserId()}`),
    role_id: Number(`${getRoleId()}`),
    leave_type_name: info?.name,
    leave_type_description: info?.description,
    leave_unit: info?.leaveunit.string,
    day_count: info?.daycount.string,
    notice_day_count: info?.noticedaycount.string,
    paid_leave_flag: info?.paid ? 1 : 0,
    allow_negative_balance_flag: info?.allowNegative ? 1 : 0,
    reason_required_flag: info?.requireReason ? 1 : 0,
    leave_type_active_flag: info?.active ? 1 : 0,
    leave_count: Number(info?.days.string),
  };

  const url = `${BASE_URL}/companyLeavePlanner`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const updateCompanyLeavePlanner = async (info) => {
  const bodyDate = {
    company_id: Number(`${getCompanyId()}`),
    user_id: Number(`${getUserId()}`),
    role_id: Number(`${getRoleId()}`),
    comp_leave_planner_id: info?.id,
    leave_type_name: info?.name,
    leave_type_description: info?.description,
    leave_unit: info?.leaveunit.string,
    day_count: info?.daycount.string,
    notice_day_count: info?.noticedaycount.string,
    paid_leave_flag: info?.paid ? 1 : 0,
    allow_negative_balance_flag: info?.allowNegative ? 1 : 0,
    reason_required_flag: info?.requireReason ? 1 : 0,
    leave_type_active_flag: info?.active ? 1 : 0,
    leave_count: Number(info?.days?.string),
  };

  const url = `${BASE_URL}/updateCompanyLeavePlanner`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const getLeaveForApprovalsCheck = async () => {
  const url = `${BASE_URL}/getLeaveForApprovals/${new Date().getFullYear()}?page=1&page_size=25&search=`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteUserLeave = async (info) => {
  const url = `${BASE_URL}/deleteUserLeave`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      employee_leave_id: info?.employee_leave_id,
      company_id: Number(`${getCompanyId()}`),
      user_id: info?.user_id,
    }),
  });
  const data = await response.json();
  return data;
};

export const postLeaveApproveByFirstApprover = async (info, no) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no,
  };

  const url = `${BASE_URL}/leaveApproveByFirstApprover`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const postLeaveApproveBySecondApprover = async (info, no) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no,
  };

  const url = `${BASE_URL}/leaveApproveBySecondApprover`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const postLeaveApproveByHR = async (info, no, comment) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no,
    message: comment,
  };

  const url = `${BASE_URL}/leaveApproveByHr/2023`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export function localToUTC(localDate) {
  if (!localDate) {
    return null;
  }

  const parsedDate = parseDate(localDate);

  if (Number.isNaN(parsedDate.getTime())) {
    return null;
  }

  const year = parsedDate.getUTCFullYear();
  const month = String(parsedDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(parsedDate.getUTCDate()).padStart(2, '0');
  const hours = String(parsedDate.getUTCHours()).padStart(2, '0');
  const minutes = String(parsedDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(parsedDate.getUTCSeconds()).padStart(2, '0');

  const utcString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

  return utcString;
}

function parseDate(dateString) {
  // Example: "Fri Nov 17 2023 00:52:13 GMT+0500 (Pakistan Standard Time)"
  if (typeof dateString === 'string') {
    return new Date(dateString);
  }

  // Example: "16/11/2023, 14:02:11"
  if (typeof dateString === 'string' && dateString.includes(',')) {
    const [day, month, year, time] = dateString.split(/[\s,/:]+/);
    const [hours, minutes, seconds] = time.split(':').map(Number);

    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  }

  return new Date(); // Default to the current date if parsing fails
}

export function formatAMPM(date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let ampm;

  if (hours >= 12) {
    ampm = 'PM';
    if (hours > 12) {
      hours -= 12;
    }
  } else {
    ampm = 'AM';
  }

  if (hours === 0) {
    hours = 12;
  }

  let minutesStr;
  if (minutes < 10) {
    minutesStr = `0${minutes}`;
  } else {
    minutesStr = minutes;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }

  return `${hours}:${minutesStr} ${ampm}`;
}

export function convertUTCToLocal(UTCDate, time) {
  if (UTCDate === undefined || !UTCDate) {
    return null;
  }
  const d = new Date(`${UTCDate}`);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  // Oct 25, 2023
  const dt = `${d.toLocaleString('default', { month: 'short' })} ${day}, ${d.getFullYear()} `;
  if (month <= 9) {
    month = `0${month}`;
  }
  if (day <= 9) {
    day = `0${day}`;
  }
  if (time === true) {
    return formatAMPM(d);
  }
  return dt;
}

export function convertUTCToLocalDate2(UTCDate) {
  if (UTCDate === undefined || !UTCDate) {
    return null;
  }

  // Parse the UTC date
  const d = new Date(UTCDate);

  // Format the local date in 'll' format
  const localDate = d.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return localDate;
}

export function formatBreakTime(totalBreakTime) {
  if (!totalBreakTime) {
    return null;
  }
  const [hours, minutes, seconds] = totalBreakTime.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes + seconds / 60;
  const remainingMinutes = totalMinutes % 60;
  const totalHours = Math.floor(totalMinutes / 60);
  if (totalHours === 0) {
    return `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
  }
  if (remainingMinutes === 0) {
    return `${totalHours} hour${totalHours !== 1 ? 's' : ''}`;
  }
  return `${totalHours} hour${totalHours !== 1 ? 's' : ''} ${remainingMinutes} minute${
    remainingMinutes !== 1 ? 's' : ''
  }`;
}

import { BASE_URL, jwtToken } from './server';
import { getCompanyId } from '../utils/utilities';
// import { convertTimeToUTC, localToIsoStringStartDay } from '../utilities/dates-conversion';

// Get All companies
export const getAllCompanies = async (id) => {
  const url = `${BASE_URL}/getChildCompany?ParentCompanyId=${id}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

//   Get Company Assests
export const getCompanyAsset = async (page, rowsPerPage, order, orderBy, searchQuery, companyId) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  if (page && page !== '') queryparams += `page=${page}&`;
  if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

  const url = `${BASE_URL}/getItemRecord/?${queryparams}company_id=${companyId}&search=${searchQuery}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get Company
export const getCompany = async (id) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getCompanyById/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Delete the company Document
export const deleteCompanyDocument = async (id, companyDocumentAutoId) => {
  const url = `${BASE_URL}/deleteCompanyDocument?company_document_auto_id=${companyDocumentAutoId}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

// Get Company Document
export const getCompanyDocument = async (filterValues, page, rowsPerPage, order, orderBy, searchQuery, companyId) => {
  const values = { ...filterValues };
  let queryparams = '';
  Object.entries(values).forEach(([key, value]) => {
    queryparams += `${key}=${value}&`;
  });

  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (page && page !== '') queryparams += `page=${page}&`;
  if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

  const url = `${BASE_URL}/getCompanyDocuments/?${queryparams}getCompanyDocument=${companyId}&search=${searchQuery}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get Child Company
export const getChildCompany = async (single = true, id) => {
  const childcompany = id ? `&childCompId=${id || ''}` : '';
  let allComp = null;
  if (single) {
    allComp = `&allChildParentCompId=${Number(getCompanyId()) || ''}`;
  } else {
    allComp = `&ParentCompId=${Number(getCompanyId()) || ''}`;
  }
  const url = `${BASE_URL}/getChildCompany?${allComp}${childcompany}`; // for all verified or unverified sub companies only
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get Company Timing
export const companyTiming = async () => {
  const url = `${BASE_URL}/getCompanyOfficeTimings/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Delete User Assests
export const deleteUserAsset = async (id, userId, companyAssetAutoId) => {
  const url = `${BASE_URL}/assets/deleteUserAsset?company_id=${id}&assign_to=${userId}&asset_auto_id=${companyAssetAutoId}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

export const getCompanyAssetRecord = async (
  filterValues,
  page,
  rowsPerPage,
  order,
  orderBy,
  searchQuery,
  companyId,
  csv = '',
  queryParams
) => {
  const values = { ...filterValues };
  let queryparams = '';
  Object.entries(values).forEach(([key, value]) => {
    queryparams += `${key}=${value}&`;
  });

  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (page && page !== '') queryparams += `page=${page}&`;
  if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

  const selectedQueryParams = queryParams || '';

  let url = `${BASE_URL}/assets/getassetrecord/?company_id=${companyId}&search=${searchQuery}&${queryparams}`;

  if (csv !== '') {
    url += `&${csv}`;
  }

  if (selectedQueryParams !== '') {
    url += `&columns=${selectedQueryParams}`;
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUserAssetsRecord = async (
  filterValues,
  page,
  rowsPerPage,
  order,
  orderBy,
  searchQuery,
  companyId,
  assignTo
) => {
  const values = { ...filterValues };
  let queryparams = '';
  Object.entries(values).forEach(([key, value]) => {
    queryparams += `${key}=${value}&`;
  });

  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (page && page !== '') queryparams += `page=${page}&`;
  if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

  const url = `${BASE_URL}/assets/getassetrecord/?assign_to=${assignTo}&company_id=${companyId}&search=${searchQuery}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsers = async (order, orderBy) => {
  let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}`;
  if (orderBy && orderBy !== '') url += `?${orderBy}=${order}&`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const companyCheck = async (name) => {
  const skillObj = {
    company_name: name,
  };
  const response = await fetch(`${BASE_URL}/uniqueCompany`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillObj),
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsersForList = async (page, rows, query) => {
  let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}?sortByName=asc&`;
  if (query && query !== '') url += `search=${query}&`;

  if (page !== null) url += `page=${page}&`;

  if (rows !== null) url += `page_size=${rows}`;

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

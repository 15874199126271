//  login
import { getUserId } from '../utils/utilities';

import { jwtToken, BASE_URL } from './server';

export const postLogin = async (email, password) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/public/login`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_username: email,
      user_password: password,
    }),
  });
  const data = await response.json();
  return data;
};

//  register

export const postSignup = async (ufn, uln, email) => {
  // if (!isAuthentic()) return;

  const bodyData = {
    user_first_name: ufn,
    user_last_name: uln,
    user_username: email,
  };

  if (!bodyData.user_first_name) {
    delete bodyData.user_first_name;
  }
  if (!bodyData.user_last_name) {
    delete bodyData.user_last_name;
  }

  const url = `${BASE_URL}/public/signup`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postResendEmail = async (email) => {
  // if (!isAuthentic()) return;

  const bodyData = {
    user_username: email,
  };

  const url = `${BASE_URL}/resendInviteUser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
//    forget password
export const postForgotpassword = async (email) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/forgotpassword`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_username: email,
    }),
  });
  const data = await response.json();
  return data;
};
//   change password
export const changePassword = async (oldPassword, newPassword, confirmPassword) => {
  const url = `${BASE_URL}/updatePassword/${getUserId()}`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    }),
  });
  const data = await response.json();
  return data;
};

//  pasword check
export const passwordCheck = async (values) => {
  const url = `${BASE_URL}/passwordCheck`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${values}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

import { BASE_URL, jwtToken } from './server';

export const getEstHrByUser = async (userId, project) => {
  const url = `${BASE_URL}/getEstHrByUser/${userId}/project/${project}/`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getSpentHourByUser = async (userId, project) => {
  const url = `${BASE_URL}/getSpentHourByUser/${userId}/project/${project}/`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getTaskStatusesbyUserAndProj = async (userId, projId) => {
  const url = `${BASE_URL}/getProjectTaskStatusesbyUserAndProjectId/${userId}/project/${projId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getReverseUser = async (superAdminToUser) => {
  const url = `${BASE_URL}/reverseUser?user_id=${superAdminToUser}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

import CryptoJS from 'crypto-js';
import moment from 'moment';
import { FORMAT_LL } from './dates-conversion';

// User Email
export const getUserEmail = () => {
  const userEmail = localStorage.getItem('sd_key_1');
  return userEmail ? JSON.parse(userEmail) : null;
};

export const setUserEmail = (email = '') => {
  localStorage.setItem('sd_key_1', JSON.stringify(email));
};

// User Role ID
export const getRoleId = () => {
  if (localStorage.getItem('sd_key_2')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_2'), process.env.REACT_APP_ENCRYPTION_KEY);
    return Number(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
  }
  return null;
};

export const setRoleId = (id = null) => {
  localStorage.setItem(
    'sd_key_2',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

// User Session
export const getUserSession = () => {
  if (localStorage.getItem('sd_key_8')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_8'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserSession = (sdKey8 = null) => {
  localStorage.setItem(
    'sd_key_8',
    CryptoJS.AES.encrypt(JSON.stringify(sdKey8), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

// User ID
export const getUserId = () => {
  if (localStorage.getItem('sd_key_3')) {
    return JSON.parse(localStorage.getItem('sd_key_3'));
  }
  return null;
};

export const setUserId = (id = null) => {
  localStorage.setItem('sd_key_3', JSON.stringify(id));
};

// Company Name
export const setCompanyName = (companyName = '') => {
  localStorage.setItem('sd_key_4', JSON.stringify(companyName));
};

export const getCompanyName = () => {
  const companyName = localStorage.getItem('sd_key_4');
  return companyName ? JSON.parse(companyName) : null;
};

// Owner
export const getIsOwner = () => {
  const isOwner = localStorage.getItem('sd_key_5');
  return isOwner ? JSON.parse(isOwner) : null;
};

export const setIsOwner = (owner = 0) => {
  localStorage.setItem('sd_key_5', JSON.stringify(owner));
};

// User State
export const getUserState = () => {
  const userState = localStorage.getItem('sd_key_6');
  return userState ? JSON.parse(userState) : null;
};

export const setUserState = (state = null) => {
  localStorage.setItem('sd_key_6', JSON.stringify(state));
};

// User Name
export const getUserName = () => {
  const userName = localStorage.getItem('sd_key_7');
  return userName ? JSON.parse(userName) : null;
};

export const setUserName = (sdKey7 = '') => {
  localStorage.setItem('sd_key_7', JSON.stringify(sdKey7));
};

// Global Project Id
export const getGlobalProjectId = () => {
  const globalProjectId = localStorage.getItem('sd_key_209');
  return globalProjectId ? JSON.parse(globalProjectId) : null;
};

export const setGlobalProjectId = (id = null) => {
  localStorage.setItem('sd_key_209', JSON.stringify(id));
};

// Global Project Name
export const getGlobalProjectName = () => {
  const globalProjectName = localStorage.getItem('sd_key_221');
  return globalProjectName ? JSON.parse(globalProjectName) : null;
};

export const setGlobalProjectName = (id = null) => {
  localStorage.setItem('sd_key_221', JSON.stringify(id));
};

// User
export const getUser = () => {
  const user = localStorage.getItem('sd_key_10');
  return user ? JSON.parse(user) : null;
};

export const setUser = (user = null) => {
  localStorage.setItem('sd_key_10', JSON.stringify(user));
};

// Company Id
export const getCompanyId = () => {
  const companyId = localStorage.getItem('sd_key_11');
  return companyId ? JSON.parse(companyId) : null;
};

export const setCompanyId = (id = null) => {
  localStorage.setItem('sd_key_11', JSON.stringify(id));
};

// User Country
export const getUserCountry = () => {
  const userCountry = localStorage.getItem('sd_key_12');
  return userCountry ? JSON.parse(userCountry) : null;
};

export const setUserCountry = (country = null) => {
  localStorage.setItem('sd_key_12', JSON.stringify(country));
};

// User City
export const getUserCity = () => {
  const userCity = localStorage.getItem('sd_key_13');
  return userCity ? JSON.parse(userCity) : null;
};

export const setUserCity = (city = null) => {
  localStorage.setItem('sd_key_13', JSON.stringify(city));
};

// Country Code ISO
export const getCountryCodeIso = () => {
  const countryCodeIso = localStorage.getItem('sd_key_14');
  return countryCodeIso ? JSON.parse(countryCodeIso) : null;
};

export const setCountryCodeIso = (id = null) => {
  localStorage.setItem('sd_key_14', JSON.stringify(id));
};

// Project Id
export const getProjectExist = () => {
  const projectExist = localStorage.getItem('sd_key_15');
  return projectExist ? JSON.parse(projectExist) : null;
};

export const setProjectExist = (id = null) => {
  localStorage.setItem('sd_key_15', JSON.stringify(id));
};

// Access Token
export const getAccessToken = () => {
  if (localStorage.getItem('sd_key_16')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setAccessToken = (token = null) => {
  localStorage.setItem(
    'sd_key_16',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

// JWT Token
export const getJwtToken = () => {
  if (localStorage.getItem('sd_key_17')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setJwtToken = (token = null) => {
  localStorage.setItem(
    'sd_key_17',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

// Access Tokem for Super Admin
export const getAccessTokenForSuperAdmin = () => {
  if (localStorage.getItem('sd_key_16')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setAccessTokenForSuperAdmin = (token = null) => {
  localStorage.setItem(
    'sd_key_16',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

// JWT Token for Super Admin
export const getJwtTokenForSuperAdmin = () => {
  if (localStorage.getItem('sd_key_17')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setJwtTokenForSuperAdmin = (token = null) => {
  localStorage.setItem(
    'sd_key_17',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

// User ID Super Admin
export const getUserIdSuperAdmin = () => {
  const userIdSuperAdmin = localStorage.getItem('sd_key_20');
  return userIdSuperAdmin ? JSON.parse(userIdSuperAdmin) : null;
};

export const setUserIdSuperAdmin = (id = null) => {
  localStorage.setItem('sd_key_20', JSON.stringify(id));
};

export const toISOLocal = (d) => {
  const z = (n) => `0${n}`.slice(-2);
  return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}`;
};

// export const convertUtcToLocal = (utcTime) => {
//   // Specify the correct input format to match the timestamp format in "created_time"
//   const inputFormat = 'YYYY-MM-DD HH:mm:ss';
//   // Create a moment object with the specified format
//   const utcMoment = moment(utcTime, inputFormat);
//   // Convert the UTC time to the local time
//   const localMoment = utcMoment.local();
//   return localMoment.format('hh:mm A');
// };

// export const convertUtcToLocal = (utcTime, userTimeZone) => {
//   // Specify the input format to match your timestamp format
//   const inputFormat = 'YYYY-MM-DD HH:mm:ss';
//   // Create a moment object with the specified format and the user's time zone
//   const localMoment = moment.tz(utcTime, inputFormat, userTimeZone);
//   return localMoment.format('hh:mm A');
// };

// export const utcToLocal = (utcdateTime, tz) => {
//   const zone = moment.tz(tz).format('Z'); // Actual zone value e:g +5:00
//   const zoneValue = zone.replace(/[^0-9: ]/g, ''); // Zone value without + - chars
//   const operator = zone && zone.split('') && zone.split('')[0] === '-' ? '-' : '+'; // operator for addition subtraction
//   let localDateTime;
//   const hours = zoneValue.split(':')[0];
//   const minutes = zoneValue.split(':')[1];
//   if (operator === '-') {
//     localDateTime = moment(utcdateTime).subtract(hours, 'hours').subtract(minutes, 'minutes').format('hh:mm a');
//   } else if (operator) {
//     localDateTime = moment(utcdateTime).add(hours, 'hours').add(minutes, 'minutes').format('hh:mm a');
//   } else {
//     localDateTime = 'Invalid Timezone Operator';
//   }
//   return localDateTime;
// };

export const convertUTCToLocal = (utcDt, utcDtFormat) => {
  const toDt = moment.utc(utcDt, utcDtFormat).toDate();
  return moment(toDt).format('hh:mm a');
};

export const convertUTCToLocalDate = (utcDt, utcDtFormat) => {
  const toDt = moment.utc(utcDt, utcDtFormat).toDate();
  return moment(toDt).format(FORMAT_LL);
};

export const getAvailableStatuses = () => [
  { id: 1, name: 'Assigned' },
  { id: 2, name: 'Un-assigned' },
  { id: 3, name: 'Damage' },
  { id: 4, name: 'Sold' },
];

// Store Token
export const storeToken = (token) => {
  const timestamp = Date.now();
  const encryptedToken = CryptoJS.AES.encrypt(token, process.env.REACT_APP_ENCRYPTION_KEY).toString();
  const encryptedTimestamp = CryptoJS.AES.encrypt(
    timestamp.toString(),
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
  localStorage.setItem('sd_key_22', encryptedToken);
  localStorage.setItem('sd_key_23', encryptedTimestamp);
};

export const getToken = () => {
  const encryptedToken = localStorage.getItem('sd_key_22');

  if (encryptedToken) {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, process.env.REACT_APP_ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  return null;
};

// Get Authentication Time Stamp
export const getAuthTimestamp = () => {
  const timestamp = localStorage.getItem('sd_key_23');
  return timestamp || null;
};

const TOKEN_EXPIRY_TIME = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

// export const isTokenExpired = () => {
//   const timestamp = localStorage.getItem('authTimestamp');
//   if (!timestamp) return true;
//   const currentTime = Date.now();
//   return currentTime - timestamp > TOKEN_EXPIRY_TIME;
// };

export const isTokenExpired = () => {
  const jwtToken = getJwtToken();
  const session = getUserSession();
  if (!jwtToken || !session) return true;
  const currentTime = moment();
  const sessionTime = moment(session);
  const diff = currentTime.diff(sessionTime, 'milliseconds');

  return diff > TOKEN_EXPIRY_TIME;
};

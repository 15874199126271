import PropTypes from 'prop-types';
// material

import { Box } from '@mui/material';
import ImgLogo from '../assets/logoimg.webp';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 50, height: 50, ...sx }}>
      <img src={ImgLogo} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </Box>
  );
}

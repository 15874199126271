import { jwtToken, BASE_URL } from './server';
import { localToIsoStringStartDay, convertTimeToUTC } from '../utils/dates-conversion';

export const postDisableUser = async (usedId, no) => {
  const bodyDate = {
    user_is_active: no,
    id: usedId,
  };

  const url = `${BASE_URL}/disableUser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const submitProfile = async (values, userId) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/users/${userId}/profile`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const editEmployeeInformation = async (user, employee, values, fm, rm) => {
  const bodyData = {};
  bodyData.user_id = user;
  bodyData.employee_id = employee;

  if (values?.firstname) bodyData.user_first_name = values?.firstname;

  if (values?.lastname) bodyData.user_last_name = values?.lastname;

  if (values?.timeIn) bodyData.office_in_timing = convertTimeToUTC(values.timeIn);
  else bodyData.office_in_timing = '';

  if (values?.timeOut) bodyData.office_out_timing = convertTimeToUTC(values?.timeOut);
  else bodyData.office_out_timing = '';

  if (values?.exFlag) bodyData.attendance_excluding_flag = values?.exFlag;

  if (values?.phone) bodyData.user_phone_number = values?.phone;
  else bodyData.user_phone_number = '';

  if (values?.joiningDate) bodyData.joining_date = localToIsoStringStartDay(values?.joiningDate);

  if (values?.birthDate) bodyData.user_birth_date = localToIsoStringStartDay(values?.birthDate);

  if (values?.nationalID) bodyData.cnic = values?.nationalID;
  else bodyData.cnic = '';

  if (values.probationDate) bodyData.probation_period = localToIsoStringStartDay(values.probationDate);

  if (values.officeHours) bodyData.user_working_hours = values?.officeHours;
  else bodyData.user_working_hours = '';

  if (fm?.user_first_name) {
    bodyData.functional_manager_name = `${fm.user_first_name} ${fm.user_last_name}`;
  } else {
    bodyData.functional_manager_name = null;
  }

  if (rm?.user_first_name) bodyData.recruitment_manager_name = `${rm?.user_first_name} ${rm?.user_last_name}`;
  else bodyData.recruitment_manager_name = null;

  if (fm?.user_first_name) bodyData.functional_manager = fm?.id;
  else bodyData.functional_manager = null;

  if (rm?.user_first_name) bodyData.recruitment_manager = rm?.id;
  else bodyData.recruitment_manager = null;

  bodyData.role_id = values.roleId;
  bodyData.user_is_active = values.userIsActive;
  bodyData.is_enabled = values.isEnabled;
  bodyData.attendance_excluding_flag = values.isExclude ? 1 : 0;

  const url = `${BASE_URL}/editEmployeeInformation`;

  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const getUserProfileInfo = async (userId) => {
  const response = await fetch(`${BASE_URL}/getUserProfileInfo/${userId}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // backgroundColor: theme.palette.grey[isLight ? 800 : 700],
          display: '-webkit-box',
          webkitLineClamp: 3,
          webkitBoxOrient: 'vertical',
          fontWeight: 500,
          fontSize: '12px',
          borderWidth: 1,
          borderRadius: 8,
          borderStyle: 'solid',
          textAlign: 'justify',
          // maxWidth: 'none',
          overflow: 'hidden',
          // whiteSpace: 'nowrap',
          // textOverflow: 'ellipsis',
          borderColor: theme.palette.grey[isLight ? 300 : 600],
          backgroundColor: theme.palette.grey[isLight ? 100 : 800],
          color: theme.palette.grey[isLight ? 900 : 200],
        },
        arrow: {
          color: theme.palette.grey[isLight ? 100 : 800],
        },
      },
    },
  };
}

import 'simplebar-react/dist/simplebar.min.css';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ThemeConfig from './theme';
import { AuthContext } from './contexts/JWTContext';
import useAuth from './hooks/useAuth';
import RtlLayout from './components/RtlLayout';
import Spinner from './components/Spinner';
import NotistackProvider from './components/NotistackProvider';
import './styles/Scrollbar.css';
import './styles/index.css';
import {
  getGlobalProjectId,
  getGlobalProjectName,
  getJwtToken,
  isTokenExpired,
  setGlobalProjectId,
  setGlobalProjectName,
} from './utils/utilities';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import Router from './routes';

export default function App() {
  const { isInitialized } = useAuth();
  const Navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const setGlobalProjectIdLogout = getGlobalProjectId();
  const setGlsetGlobalProjectNameLogout = getGlobalProjectName();
  
  useEffect(() => {
    // Check if it's the first time loading the page
    const isFirstLoad = localStorage.getItem('isFirstLoad');

    if (!isFirstLoad) {
      // Clear localStorage and set the flag
      localStorage.clear();
      localStorage.setItem('isFirstLoad', 'true');
    }
  }, []);
  useEffect(
    () => {
      const handleLogout = (event) => {
        if (event.key === 'logout') {
          Navigate('/');
        }
      };
      window.addEventListener('storage', handleLogout);
      const jwtToken = getJwtToken();
      if (jwtToken) {
        if (isTokenExpired()) {
          // localStorage.clear();
          logout();
          setGlobalProjectId(setGlobalProjectIdLogout);
          setGlobalProjectName(setGlsetGlobalProjectNameLogout);
          Navigate('/');
        }
      }
      const checkExpiryInterval = setInterval(() => {
        if (isTokenExpired()) {
          // localStorage.clear();
          logout();
          setGlobalProjectId(setGlobalProjectIdLogout);
          setGlobalProjectName(setGlsetGlobalProjectNameLogout);
          Navigate('/');
        }
      }, 60 * 1000);

      return () => {
        window.removeEventListener('storage', handleLogout);
        clearInterval(checkExpiryInterval);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <HelmetProvider>
      <ThemeConfig>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CollapseDrawerProvider>
            <RtlLayout>
              <NotistackProvider>{isInitialized ? <Router /> : <Spinner />}</NotistackProvider>
            </RtlLayout>
          </CollapseDrawerProvider>
        </LocalizationProvider>
      </ThemeConfig>
    </HelmetProvider>
  );
}
